import axios from 'axios';

const API_URL = 'https://api.youelite.net/api';

export const login = (email, password) => {
    return axios.post(`${API_URL}/login`, {
        email,
        password
    });
};

export const register = (name, surname, email, password, password_confirmation) => {
    return axios.post(`${API_URL}/register`, {
        name,
        surname,
        email,
        password,
        password_confirmation
    }).then(response => {
        if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem('user');
  window.location.href = '/signin';
};

export const verifyEmail = (id, hash) => {
    return axios.get(`${API_URL}/email/verify/${id}/${hash}`);
};

export const resendVerificationEmail = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return axios.post(`${API_URL}/email/resend`, {}, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    });
};

export const checkEmailVerification = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return axios.get(`${API_URL}/email/check-verification`, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    });
};