import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Form, Modal, Nav, Card } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import axios from 'axios';
import '../assets/css/AppCalendar.css';

const categoryColors = {
  'College List Deadlines': '#007bff',
  'Favorite Scholarships Deadlines': '#28a745',
  'State Deadlines': '#bf3947',
  'Federal Deadlines': '#dc3545',
  'Applications Deadlines': '#17a2b8'
};

export default function AppCalendar() {

  useEffect(() => {
    document.title = "Deadlines Management | YouElite";
  }, []);


  useEffect(() => {
    document.body.classList.add('app-calendar');
    return () => {
      document.body.classList.remove('app-calendar');
    };
  }, []);

  const [isSidebarShow, setSidebarShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [modalShow, setModalShow] = useState(false);
  const [eventDetailsShow, setEventDetailsShow] = useState(false);
  const [events, setEvents] = useState([]); // State to store fetched events
  const [selectedEvent, setSelectedEvent] = useState(null); // State to store selected event details
  const [newEvent, setNewEvent] = useState({
    title: '',
    date: new Date(),
    hour: '',
    category: 'College List Deadlines',
    college: ''
  }); // State to store new event details
  const [calendarKey, setCalendarKey] = useState(Date.now()); // Unique key for FullCalendar
  const [upcomingEvents, setUpcomingEvents] = useState([]); // State to store upcoming events

  // Add new state to track visibility of each category
  const [categoryVisibility, setCategoryVisibility] = useState({
    'College List Deadlines': true,
    'Favorite Scholarships Deadlines': true,
    'State Deadlines': true,
    'Federal Deadlines': true,
    'Applications Deadlines': true
  });

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const handleEventDetailsClose = () => setEventDetailsShow(false);

  // Fetch deadlines and custom events on component mount
  useEffect(() => {
    const fetchEvents = async () => {
      const user = localStorage.getItem('user');
      if (!user) {
        console.error('User is not authenticated');
        return;
      }

      const { token } = JSON.parse(user);

      try {
        // Fetch all data in parallel
        const [
          deadlinesResponse,
          eventsResponse,
          savedScholarshipsResponse,
          applicationsResponse
        ] = await Promise.all([
          axios.get("https://api.youelite.net/api/user/colleges/deadlines", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          axios.get("https://api.youelite.net/api/events", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          axios.get("https://api.youelite.net/api/scholarships/saved", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          axios.get("https://api.youelite.net/api/applications", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
        ]);

        // Combine deadlines, custom events, saved scholarships, and applications
        const combinedEvents = [
          ...deadlinesResponse.data.map(event => ({ 
            ...event, 
            extendedProps: { 
              ...event.extendedProps,
              userCreated: false,
              category: 'College List Deadlines'  
            }, 
            backgroundColor: categoryColors['College List Deadlines'] 
          })),
          ...eventsResponse.data.map(event => ({ 
            ...event, 
            extendedProps: { 
              ...event.extendedProps,
              userCreated: true,
              category: event.category || 'Others'  
            }, 
            backgroundColor: categoryColors[event.category] || '#6c757d' 
          })),
          ...savedScholarshipsResponse.data.map(savedScholarship => ({
            id: savedScholarship.id,
            title: savedScholarship.scholarship.program_name,
            start: savedScholarship.scholarship.close_date, // Scholarship deadline
            extendedProps: {
              scholarshipId: savedScholarship.scholarship.id,
              category: 'Favorite Scholarships Deadlines'
            },
            backgroundColor: categoryColors['Favorite Scholarships Deadlines']
          })),
          // Add application submission dates
          ...applicationsResponse.data.applications
            .filter(application => application.status === 'not started' || application.status === 'in progress')
            .map(application => ({
              id: application.id,
              title: `Application: ${application.college.INSTNM} - ${application.major} Submission Deadline`,
              start: application.submission_date, // Application submission date
              extendedProps: {
                applicationId: application.id,
                category: 'Applications Deadlines',
                status: application.status,
                college: application.college.INSTNM
              },
              backgroundColor: categoryColors['Applications Deadlines']
            }))
        ];

        // Filter and sort events
        const filteredEvents = combinedEvents.filter(event => new Date(event.start || event.date) >= new Date());
        const sortedEvents = filteredEvents.sort((a, b) => new Date(a.start || a.date) - new Date(b.start || b.date));
        const closestThreeEvents = sortedEvents.slice(0, 3);

        setEvents(combinedEvents);
        setUpcomingEvents(closestThreeEvents);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch events:', error);
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, []);


  const LoadingScreen = () => (
    <div className="loading-screen">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <p>Loading calendar...</p>
    </div>
  );
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({
      ...newEvent,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setNewEvent({
      ...newEvent,
      date
    });
  };

  const handleSaveEvent = async () => {
    const user = localStorage.getItem('user');
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const { token } = JSON.parse(user);

    // Format the date to YYYY-MM-DD
    const formattedDate = newEvent.date.toISOString().split('T')[0];

    try {
      // Save event to the backend
      const response = await axios.post("https://api.youelite.net/api/events", {
        ...newEvent,
        date: formattedDate, // Use the formatted date
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Update events state to include the new event
      const updatedEvents = [...events, {...response.data, extendedProps: { userCreated: true }, backgroundColor: categoryColors[newEvent.category] }];
      setEvents(updatedEvents);
      setCalendarKey(Date.now()); // Force FullCalendar re-render

      // Update upcoming events
      const filteredEvents = updatedEvents.filter(event => new Date(event.start || event.date) >= new Date());
      const sortedEvents = filteredEvents.sort((a, b) => new Date(a.start || a.date) - new Date(b.start || b.date));
      setUpcomingEvents(sortedEvents.slice(0, 3));

      handleModalClose();
    } catch (error) {
      console.error('Failed to save event:', error);
    }
  };

  const requestReminder = async (eventId) => {
    const user = localStorage.getItem('user');
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.post(`https://api.youelite.net/api/events/${eventId}/remind`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      alert('Email reminder requested successfully');
    } catch (error) {
      console.error('Failed to request email reminder:', error);
      alert('Failed to request email reminder');
    }
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setEventDetailsShow(true);
  };

  const handleDeleteEvent = async () => {
    const user = localStorage.getItem('user');
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.delete(`https://api.youelite.net/api/events/${selectedEvent.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Update events state to immediately reflect deletion
      const updatedEvents = events.filter(event => event.id !== selectedEvent.id);
      setEvents(updatedEvents);
      setCalendarKey(Date.now()); // Force FullCalendar re-render

      // Update upcoming events
      const filteredEvents = updatedEvents.filter(event => new Date(event.start || event.date) >= new Date());
      const sortedEvents = filteredEvents.sort((a, b) => new Date(a.start || a.date) - new Date(b.start || b.date));
      setUpcomingEvents(sortedEvents.slice(0, 3));

      handleEventDetailsClose();
    } catch (error) {
      console.error('Failed to delete event:', error);
    }
  };

  const handleRemoveFromList = async () => {
    const user = localStorage.getItem('user');
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const { token } = JSON.parse(user);
    const userCollegeId = selectedEvent.extendedProps.userCollegeId;

    try {
      await axios.delete(`https://api.youelite.net/api/user/colleges/${userCollegeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Update events state to immediately reflect removal
      const updatedEvents = events.filter(event => event.id !== selectedEvent.id);
      setEvents(updatedEvents);
      setCalendarKey(Date.now()); // Force FullCalendar re-render

      // Update upcoming events
      const filteredEvents = updatedEvents.filter(event => new Date(event.start || event.date) >= new Date());
      const sortedEvents = filteredEvents.sort((a, b) => new Date(a.start || a.date) - new Date(b.start || b.date));
      setUpcomingEvents(sortedEvents.slice(0, 3));

      handleEventDetailsClose();
    } catch (error) {
      console.error('Failed to remove from list:', error);
    }
  };

  const handleHideEvent = () => {
    const updatedEvents = events.map(event => 
      event.id === selectedEvent.id 
        ? { ...event, display: 'background', color: 'rgba(0, 0, 0, 0.1)' }
        : event
    );
    setEvents(updatedEvents);
    setCalendarKey(Date.now()); // Force FullCalendar re-render

    // Update upcoming events
    const filteredEvents = updatedEvents.filter(event => new Date(event.start || event.date) >= new Date());
    const sortedEvents = filteredEvents.sort((a, b) => new Date(a.start || a.date) - new Date(b.start || b.date));
    setUpcomingEvents(sortedEvents.slice(0, 3));

    handleEventDetailsClose();
  };

  // New function to handle category visibility toggle
  const handleCategoryToggle = (category) => {
    setCategoryVisibility(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  // Update the events filtering logic to respect visibility settings for all event types
  const visibleEvents = events.filter(event => 
    categoryVisibility[event.extendedProps.category]
  );

  const calculateDaysRemaining = (date) => {
    const eventDate = new Date(date);
    const today = new Date();
    const diffTime = eventDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  return (
    <React.Fragment>
      <Header />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={" app-calendar-page main main-calendar" + (isSidebarShow ? " show" : "")}>
          <div 
            className="calendar-sidebar"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <PerfectScrollbar 
              className="sidebar-body"
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'auto'
              }}
            >
              <div className="d-grid mb-3">
                <Button variant="primary" onClick={handleModalShow}>Create New Event</Button>
              </div>

              <div style={{ flexShrink: 0 }}>
                <h5 className="section-title section-title-sm mb-4">Upcoming Events</h5>
                <Card.Body className="p-3">
                  <ul className="events-list">
                    {upcomingEvents.length > 0 ? (
                      upcomingEvents.map((item, index) => (
                        <li key={index}>
                          <div className="event-date" style={{ backgroundColor: index === 0 ? '#007bff' : '#f2f2f2', color: index === 0 ? '#fff' : '#000' }}>
                            <small>{new Date(item.start || item.date).toLocaleString('en-US', { weekday: 'short' })}</small>
                            <h5>{new Date(item.start || item.date).getDate()}</h5>
                          </div>
                          <div className="events-body">
                            <div className="ev-item">
                              <small>{item.extendedProps.hour || 'All Day'}</small>
                              <h6>{item.title}</h6>
                              <p>{item.extendedProps.category || 'N/A'}</p>
                              <p>{calculateDaysRemaining(item.start || item.date)} days remaining</p>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>Nothing to show here!</p>
                    )}
                  </ul>
                </Card.Body>
              </div>

              <div style={{ marginTop: 'auto' }}>
                <h5 className="section-title section-title-sm mb-4">My Categories</h5>
                <Nav className="nav-calendar mb-4">
                  <Form.Check 
                    type="switch" 
                    id="college-list-deadlines-switch" 
                    label="College List Deadlines" 
                    className="custom-switch-college"
                    checked={categoryVisibility['College List Deadlines']}
                    onChange={() => handleCategoryToggle('College List Deadlines')}
                  />
                  <Form.Check 
                    type="switch" 
                    id="favorite-scholarships-deadlines-switch" 
                    label="Favorite Scholarships Deadlines" 
                    className="custom-switch-scholarships"
                    checked={categoryVisibility['Favorite Scholarships Deadlines']}
                    onChange={() => handleCategoryToggle('Favorite Scholarships Deadlines')}
                  />
                  <Form.Check 
                    type="switch" 
                    id="custom-applications-switch" 
                    label="Applications Deadlines" 
                    className="custom-applications-switch"
                    checked={categoryVisibility['Applications Deadlines']}
                    onChange={() => handleCategoryToggle('Applications Deadlines')}
                  />
                  <Form.Check 
                    type="switch" 
                    id="state-deadlines-switch" 
                    label="State Deadlines (Coming Soon)" 
                    className="custom-switch-state"
                    checked={categoryVisibility['State Deadlines']}
                    onChange={() => handleCategoryToggle('State Deadlines')}
                  />
                  <Form.Check 
                    type="switch" 
                    id="federal-deadlines-switch" 
                    label="Federal Deadlines (Coming Soon)" 
                    className="custom-switch-federal"
                    checked={categoryVisibility['Federal Deadlines']}
                    onChange={() => handleCategoryToggle('Federal Deadlines')}
                  />
                </Nav>
              </div>
            </PerfectScrollbar>
          </div>
          <div className="calendar-body">
            <FullCalendar
              key={calendarKey} // Unique key to force re-render
              plugins={[dayGridPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                "left": "custom1 prev,next today",
                "center": "title",
                "right": "dayGridMonth,timeGridWeek,timeGridDay"
              }}
              events={visibleEvents} // Use the filtered events here
              customButtons={{
                custom1: {
                  icon: "chevron-left",
                  click: function () {
                    setSidebarShow(!isSidebarShow);
                  }
                }
              }}
              eventClick={handleEventClick}
            />
          </div>
        </div>
      )}

      <Modal className="modal-event" show={modalShow} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Form.Label>Event Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title of event"
              name="title"
              value={newEvent.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <Form.Label>Date:</Form.Label>
            <ReactDatePicker
              selected={newEvent.date}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <Form.Label>Hour (optional):</Form.Label>
            <Form.Control
              type="time"
              name="hour"
              value={newEvent.hour}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <Form.Label>Category:</Form.Label>
            <Form.Select
              name="category"
              value={newEvent.category}
              onChange={handleInputChange}
            >
              <option value="College List Deadlines">College List Deadlines</option>
              <option value="Favorite Scholarships Deadlines">Favorite Scholarships Deadlines</option>
              <option value="State Deadlines">State Deadlines</option>
              <option value="Federal Deadlines">Federal Deadlines</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label>College (optional):</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter college name"
              name="college"
              value={newEvent.college}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEvent}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={eventDetailsShow} onHide={handleEventDetailsClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <>
              <p><strong>Title:</strong> {selectedEvent.title}</p>
              <p><strong>Date:</strong> {selectedEvent.start.toISOString().split('T')[0]}</p>
              <p><strong>Hour:</strong> {selectedEvent.extendedProps.hour || 'N/A'}</p>
              <p><strong>Category:</strong> {selectedEvent.extendedProps.category || 'N/A'}</p>
              <p><strong>College:</strong> {selectedEvent.extendedProps.college || 'N/A'}</p>
              {selectedEvent.extendedProps.userCreated ? (
                <>
                  <Button variant="primary" style={{ marginRight: '10px' }} onClick={handleDeleteEvent}>Delete Event</Button>
                </>
              ) : (
                <>
                  <Button variant="primary" onClick={handleRemoveFromList} style={{ marginRight: '10px' }}>Remove from List</Button>
                  <Button variant="primary" onClick={handleHideEvent} style={{ marginRight: '10px' }}>Hide Event</Button>
                </>
              )}
              <Button variant="primary" onClick={() => requestReminder(selectedEvent.id)} style={{ marginRight: '10px' }}>
                Request Email Reminder
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
