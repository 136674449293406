import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Card, Col, Row, Form, Spinner, OverlayTrigger, Tooltip, ListGroup, Modal, ProgressBar } from 'react-bootstrap';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import "../assets/css/EssayReview.css";
import { FaGem, FaTimes } from 'react-icons/fa';

function PastAnalyses({ analyses, onShowAnalysis, onDeleteAnalysis }) {

  useEffect(() => {
    document.title = "Essay Review | YouElite";
  }, []);


  // Sort analyses by date (most recent first)
  const sortedAnalyses = [...analyses].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  // Get the most recent 5 analyses
  const recentAnalyses = sortedAnalyses.slice(0, 5);

  return (
    <Card className="card-one h-100 mb-3">
      <Card.Header>
        <Card.Title as="h6">Past Analyses</Card.Title>
      </Card.Header>
      <Card.Body>
        {recentAnalyses.length > 0 ? (
          <ListGroup>
            {recentAnalyses.map((analysis) => (
              <ListGroup.Item key={analysis.id} className="d-flex justify-content-between align-items-start">
                <div className="analysis-text">
                  <strong>{analysis.analysis.essay_title}</strong><br />
                  {new Date(analysis.created_at).toLocaleDateString(undefined, {
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </div>
                <div className="button-container">
                  <Button variant="outline-primary" size="sm" onClick={() => onShowAnalysis(analysis)} className="me-2">
                    Show
                  </Button>
                  <Button variant="outline-danger" size="sm" onClick={() => onDeleteAnalysis(analysis.id)}>
                    Delete
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p className="text-muted">No past analyses available. Your recent analyses will appear here.</p>
        )}
      </Card.Body>
    </Card>
  );
}


function EssayForm({ essay, setEssay, prompt, setPrompt, isCommonApp, setIsCommonApp, additionalPreferences, setAdditionalPreferences, handleSubmit, loading, pastAnalyses, onShowAnalysis, onDeleteAnalysis, remainingCredits, isFreeUser }) {
  
  // Function to calculate the progress and variant
  const getProgressBarVariant = (remainingCredits) => {
    const maxCredits = isFreeUser ? 2 : 10; // Determine max credits based on user type
    const percentage = (remainingCredits / maxCredits) * 100;

    let variant;
    if (percentage > 75) {
      variant = 'success';
    } else if (percentage > 50) {
      variant = 'info';
    } else if (percentage > 25) {
      variant = 'warning';
    } else {
      variant = 'danger';
    }

    return { percentage, variant };
  };

  // Get the progress bar values
  const { percentage, variant } = getProgressBarVariant(remainingCredits);
  
  
  return (
    <Row className="g-3 flex-grow-1">
      <Col md="8">
        <Card className="card-one h-100">
          <Card.Body>
            <Form.Control
              as="textarea"
              className="form-control-textarea-lg"
              placeholder="Paste your essay here"
              value={essay}
              onChange={(e) => setEssay(e.target.value)}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col md="4" className="d-flex flex-column">
        <div className="position-relative essay-form-wrapper"> {/* Specific wrapper class */}
          <Card className="card-one h-100 mb-3 flex-grow-1">
            <Card.Header>
              <Card.Title as="h6">Essay Prompt</Card.Title>
            </Card.Header>
            <Card.Body style={isFreeUser ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
              <Form.Check
                type="checkbox"
                id="commonAppCheck"
                label="This is a CommonApp Essay"
                checked={isCommonApp}
                onChange={(e) => setIsCommonApp(e.target.checked)}
                className="mb-3"
                disabled={isFreeUser}  // Disable for free users
              />
              {isCommonApp ? (
                <Form.Control
                  as="select"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  disabled={isFreeUser}  // Disable for free users
                >
                {/* Add your options here */}
                <option value="Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.">1: Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.
                </option>
                <option value="The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?">2: The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?
                </option>
                <option value="Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?">3: Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?
                </option>
                <option value="Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you?">4: Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you?
                </option>
                <option value="Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.">5: Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.
                </option>
                <option value="Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?">6: Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?
                </option>
                <option value="Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design.">7: Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design.
                </option>
                {/* Add more options as needed */}
                </Form.Control>
              ) : (
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Paste your essay prompt"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  disabled={isFreeUser}  // Disable for free users
                />
              )}
            </Card.Body>
          </Card>

          {isFreeUser && (
            <div className="essay-upgrade-overlay"> {/* Specific overlay class */}
              <Link to="/plans"> 
                <Button variant="primary" className="essay-upgrade-button"> {/* Specific button class */}
                  <FaGem className="me-1" /> Upgrade
                </Button>
              </Link>
            </div>
          )}
        </div>

        <div className="position-relative essay-form-wrapper"> {/* Specific wrapper class */}
          <Card className="card-one h-100 mb-3 flex-grow-1">
            <Card.Header>
              <Card.Title as="h6">Additional Preferences</Card.Title>
            </Card.Header>
            <Card.Body style={isFreeUser ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Add any additional request"
                value={additionalPreferences}
                onChange={(e) => setAdditionalPreferences(e.target.value)}
                className="overflow-auto"
                disabled={isFreeUser}  // Disable for free users
              />
            </Card.Body>
          </Card>

          {isFreeUser && (
            <div className="essay-upgrade-overlay"> {/* Specific overlay class */}
              <Link to="/plans"> 
                <Button variant="primary" className="essay-upgrade-button"> {/* Specific button class */}
                  <FaGem className="me-1" /> Upgrade
                </Button>
              </Link>
            </div>
          )}
        </div>

        {loading ? (
          <Button variant="primary" disabled className="w-100 mt-3 mb-3">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span className="visually-hidden">Loading...</span>
          </Button>
        ) : (
          <Button variant="primary" size="lg" type="button" onClick={handleSubmit} className="w-100 mt-3 mb-3">
            Start!
          </Button>
        )}
        {remainingCredits > 0 ? (
          <div className="custom-progress-wrapper mb-3">
            <ProgressBar 
              variant={variant} 
              now={percentage} 
              label={`${remainingCredits} remaining / day`} 
              className="credits-progress-bar" 
            />
          </div>
        ) : (
          <div className="no-credits-message mb-2">
            No credits left for today!
          </div>
        )}

        <PastAnalyses analyses={pastAnalyses} onShowAnalysis={onShowAnalysis} onDeleteAnalysis={onDeleteAnalysis} />
      </Col>
    </Row>
  );
}




function ReviewResults({ essay, reviewResult, onSaveAnalysis, onBack, isSaved }) {
  const getHighlightedText = (text, sections) => {
    let parts = [];
    let lastIndex = 0;

    sections.forEach((section) => {
      const startIndex = text.indexOf(section.content, lastIndex);

      if (startIndex !== -1) {
        if (startIndex > lastIndex) {
          parts.push({
            text: text.substring(lastIndex, startIndex),
            category: null
          });
        }

        parts.push({
          text: section.content,
          category: section.category,
          explanation: section.explanation
        });

        lastIndex = startIndex + section.content.length;
      }
    });

    if (lastIndex < text.length) {
      parts.push({
        text: text.substring(lastIndex),
        category: null
      });
    }

    return parts.map((part, index) => {
      if (part.category) {
        return (
          <OverlayTrigger
            key={index}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${index}`}>
                <div className="comment-tooltip">{part.explanation}</div>
              </Tooltip>
            }
          >
            <span className={`highlight-${getCategoryClass(part.category)}`}>
              {part.text}
            </span>
          </OverlayTrigger>
        );
      } else {
        return <span key={index}>{part.text}</span>;
      }
    });
  };

  const getCategoryClass = (category) => {
    switch (category) {
      case "great":
        return "green";
      case "could_improve":
        return "yellow";
      case "must_correct":
        return "red";
      default:
        return "";
    }
  };

  const calculateAverageScore = (metrics) => {
    const {
      coherence,
      language_usage,
      originality,
      narrative_structure_and_storytelling,
      insight_and_reflection,
      imagery_and_specificity,
      overall_effectiveness,
    } = metrics;
    return (
      (
        coherence +
        language_usage +
        originality +
        narrative_structure_and_storytelling +
        insight_and_reflection +
        imagery_and_specificity +
        overall_effectiveness
      ) / 7
    ).toFixed(2);
  };

  return (
    <Row className="g-3 flex-grow-1">
      <Col md="8" className="essay-content">
        <Card className="card-one h-100">
          <Card.Body>
            <h5 className="mb-3">{reviewResult.essay_title}</h5> {/* Add this line */}
            <div className="essay-text">{getHighlightedText(essay, reviewResult.sections)}</div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="4" className="review-content">
        <div className="review-section">
          <Card className="card-one mb-3">
            <Card.Header>
              <Card.Title as="h6">General Review</Card.Title>
            </Card.Header>
            <Card.Body>
              <p className="mb-3"><strong>Total Score:</strong> {calculateAverageScore(reviewResult.metrics)}</p>
              <p className="mb-3">{reviewResult.overall_feedback}</p>
            </Card.Body>
          </Card>
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Specifics</Card.Title>
            </Card.Header>
            <Card.Body>
              <h6 className="mb-2">Strengths:</h6>
              <ul>
                {reviewResult.key_strengths.map((strength, index) => (
                  <li key={index}>{strength}</li>
                ))}
              </ul>
              <h6 className="mb-2">Improvements:</h6>
              <ul>
                {reviewResult.priority_improvements.map((improvement, index) => (
                  <li key={index}>{improvement}</li>
                ))}
              </ul>
              <h6 className="mb-2">Metrics:</h6>
              <ul>
                <li>Coherence: {reviewResult.metrics.coherence}</li>
                <li>Language Usage: {reviewResult.metrics.language_usage}</li>
                <li>Originality: {reviewResult.metrics.originality}</li>
                <li>Narrative Structure and Storytelling: {reviewResult.metrics.narrative_structure_and_storytelling}</li>
                <li>Insight and Reflection: {reviewResult.metrics.insight_and_reflection}</li>
                <li>Imagery and Specificity: {reviewResult.metrics.imagery_and_specificity}</li>
                <li>Overall Effectiveness: {reviewResult.metrics.overall_effectiveness}</li>
              </ul>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <Button
            variant="secondary"
            size="lg"
            onClick={onBack}
            className="w-48"
          >
            Back
          </Button>
          {!isSaved && (
            <Button
              variant="primary"
              size="lg"
              onClick={() => onSaveAnalysis(essay, reviewResult)}
              className="w-48"
            >
              Save Analysis
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default function EssayReview() {
  const [essay, setEssay] = useState('');
  const [prompt, setPrompt] = useState('');
  const [isCommonApp, setIsCommonApp] = useState(false);
  const [additionalPreferences, setAdditionalPreferences] = useState('');
  const [reviewResult, setReviewResult] = useState(null);
  const [view, setView] = useState('form');
  const [loading, setLoading] = useState(false);
  const [pastAnalyses, setPastAnalyses] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [remainingCredits, setRemainingCredits] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [error, setError] = useState(null);
  const isSubmitting = useRef(false);



  const user = JSON.parse(localStorage.getItem("user"));
  const isFreeUser = user?.user_type === 1;
  


  useEffect(() => {
    fetchPastAnalyses();
    fetchRemainingCredits();
  }, []);

  const fetchPastAnalyses = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const response = await fetch('https://api.youelite.net/api/nova/get-analyses', {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setPastAnalyses(data);
    } catch (error) {
      console.error('Error fetching past analyses:', error);
    }
  };

  const fetchRemainingCredits = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
        console.error('User not authenticated');
        return;
    }

    try {
        const response = await fetch('https://api.youelite.net/api/user/daily-credits', {
            headers: {
                'Authorization': `Bearer ${user.token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRemainingCredits(data.remaining_credits);
    } catch (error) {
        console.error('Error fetching remaining credits:', error);
    }
};



const handleSubmit = async () => {
  if (isSubmitting.current) return;    // Prevent multiple submissions
  isSubmitting.current = true;         // Set submitting flag

  setLoading(true);

  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    console.error('User not authenticated');
    setLoading(false);
    isSubmitting.current = false;      // Reset submitting flag
    return;
  }

  try {
    const response = await fetch('https://api.youelite.net/api/nova/analyze-essay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
      body: JSON.stringify({ essay, prompt, custom_instructions: additionalPreferences }),
    });

    if (!response.ok) {
      // ... handle non-OK responses
    } else {
      const data = await response.json();
      setReviewResult(data);
      setView('review');
      setIsSaved(false); // Reset saved status for new analysis
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  } finally {
    setLoading(false);
    isSubmitting.current = false;      // Reset submitting flag in finally block
  }
};


const handleUpgradeClick = () => {
  // Logic to redirect to the upgrade page or handle the upgrade action
  window.location.href = '/upgrade'; // Example: Redirect to the upgrade page
};


const renderUpgradeModal = () => (
  <Modal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} centered>
    <Modal.Body className="text-center p-4" style={{ position: 'relative' }}>
      {/* Close Button in the body */}
      <FaTimes 
        size={24} 
        color="#6c757d" 
        style={{ 
          position: 'absolute', 
          top: '10px', 
          right: '10px', 
          cursor: 'pointer' 
        }} 
        onClick={() => setShowUpgradeModal(false)}
      />

      <FaGem size={48} color="#6c757d" />
      <h4 className="my-4">Upgrade Now—Because Google Can't Get You Into College!</h4>
      <p>Sure, Google knows a lot, but it can't fill out your applications.</p>
      <p>
        For just <del>$39.99</del> <span style={{ color: '#d4af37', fontWeight: 'bold' }}>$14.99</span>, get the real tools to ace those forms. Let’s give Google a break and get you into college!
      </p>
      <Button variant="success" size="lg" onClick={handleUpgradeClick}>Upgrade and Be Smarter Than a Search Engine!</Button>
    </Modal.Body>
  </Modal>
);


  const handleSaveAnalysis = async (essay, analysis) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const response = await fetch('https://api.youelite.net/api/nova/save-analysis', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({ essay, analysis })
      });

    if (response.status === 400) {
      const data = await response.json();
      if (data.error === "Duplicate Analysis") {
        setError(data.message);  // Set error message to state
        return;  // Early return to stop further execution
      }
    }



      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await fetchPastAnalyses();
      setIsSaved(true); // mark as saved
      setError(null);  // Clear any previous error
    } catch (error) {
      console.error('Error saving analysis:', error);
    }
  };

  const handleShowAnalysis = (analysis) => {
    setEssay(analysis.essay);
    setReviewResult(analysis.analysis);
    setView('review');
    setIsSaved(true); // past analysis is already saved
  };

  const handleDeleteAnalysis = async (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const response = await fetch(`https://api.youelite.net/api/nova/delete-analysis/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await fetchPastAnalyses(); // Refresh the list of analyses
    } catch (error) {
      console.error('Error deleting analysis:', error);
    }
  };

  const handleBack = () => {
    setView('form');
    setEssay(''); // reset essay text
    setPrompt('');
    setIsCommonApp(false);
    setAdditionalPreferences('');
    setReviewResult(null);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Nova AI - Essay Review</li>
            </ol>
            <h4 className="main-title mb-0">Nova AI - Essay Review</h4>
          </div>
        </div>



        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}



        {view === 'form' ? (
          <EssayForm
            essay={essay}
            setEssay={setEssay}
            prompt={prompt}
            setPrompt={setPrompt}
            isCommonApp={isCommonApp}
            setIsCommonApp={setIsCommonApp}
            additionalPreferences={additionalPreferences}
            setAdditionalPreferences={setAdditionalPreferences}
            handleSubmit={handleSubmit}
            loading={loading}
            pastAnalyses={pastAnalyses}
            onShowAnalysis={handleShowAnalysis}
            onDeleteAnalysis={handleDeleteAnalysis}
            remainingCredits={remainingCredits}
            isFreeUser={isFreeUser} 
          />
        ) : (
          <ReviewResults
            essay={essay}
            reviewResult={reviewResult}
            onSaveAnalysis={handleSaveAnalysis}
            onBack={handleBack}
            isSaved={isSaved}
          />
        )}

        {renderUpgradeModal()}

        <Footer />
      </div>
    </React.Fragment>
  );
}
