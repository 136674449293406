// src/pages/UploadPage.js

import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import axios from "axios";

// Helper function to parse query parameters
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UploadPage = () => {
  const query = useQuery();
  const navigate = useNavigate();

  // State variables
  const [token, setToken] = useState("");
  const [courseId, setCourseId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [title, setTitle] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [topics, setTopics] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState("");

  // Constants
  const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB
  const API_BASE_URL = "https://api.youelite.net/api";

  // Parse URL parameters on component mount
  useEffect(() => {
    const parsedToken = query.get("token");
    const parsedCourseId = query.get("course_id");
    const parsedTopicId = query.get("topic_id");

    if (!parsedToken || !parsedCourseId) {
      setFetchError("Invalid upload link. Missing token or course information.");
      return;
    }

    setToken(parsedToken);
    setCourseId(parsedCourseId);
    setTopicId(parsedTopicId);
    setSelectedTopicId(parsedTopicId || "");
  }, [query]);

  // Fetch topics for the course
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/courses/${courseId}/topics`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setTopics(response.data.data);
      } catch (error) {
        console.error("Error fetching topics:", error);
        setFetchError("Failed to fetch topics. Please check your upload link.");
      }
    };

    if (courseId && token) {
      fetchTopics();
    }
  }, [courseId, token]);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileError("");
    setUploadError("");
    setUploadSuccess("");

    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setFileError("Only PDF files are allowed.");
        setFile(null);
        setFileName("");
        return;
      }

      if (selectedFile.size > MAX_FILE_SIZE) {
        setFileError("File size exceeds 30MB limit.");
        setFile(null);
        setFileName("");
        return;
      }

      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Reset errors
    setTitleError("");
    setTopicError("");
    setFileError("");
    setUploadError("");
    setUploadSuccess("");
  
    let valid = true;
  
    // Validate title
    if (!title.trim()) {
      setTitleError("Title is required.");
      valid = false;
    }
  
    // Validate topic
    if (!selectedTopicId) {
      setTopicError("Please select a topic.");
      valid = false;
    }
  
    // Validate file
    if (!file) {
      setFileError("Please select a PDF file to upload.");
      valid = false;
    }
  
    if (!valid) {
      return;
    }
  
    // Prepare form data
    const formData = new FormData();
    formData.append("document", file);
    formData.append("course_id", courseId);
    formData.append("topic_id", selectedTopicId);
    formData.append("title", title);
  
    try {
      setUploading(true);
  
      const response = await axios.post(`${API_BASE_URL}/documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      });
  
      // Check if status is in the 2xx range
      if (response.status >= 200 && response.status < 300) {
        const { message, document_id } = response.data;
        setUploadSuccess(`${message} Document ID: ${document_id}`);
        
        // Optionally, redirect the user after a delay
        setTimeout(() => {
          navigate("/"); // Redirect to home or another relevant page
        }, 3000);
      } else {
        setUploadError("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      if (error.response && error.response.data) {
        setUploadError(error.response.data.message || "Failed to upload document.");
      } else {
        setUploadError("Failed to upload document. Please try again.");
      }
    } finally {
      setUploading(false);
    }
  };

  // If there's a fetch error, display it
  if (fetchError) {
    return (
      <div style={styles.container}>
        <h2>Upload Document</h2>
        <p style={styles.error}>{fetchError}</p>
      </div>
    );
  }

  // If token or course_id is missing, don't render the form
  if (!token || !courseId) {
    return (
      <div style={styles.container}>
        <h2>Upload Document</h2>
        <p style={styles.error}>Invalid upload link.</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h2>Upload Document</h2>
      {uploadSuccess && <p style={styles.success}>{uploadSuccess}</p>}
      {uploadError && <p style={styles.error}>{uploadError}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        {/* Title Input */}
        <div style={styles.formGroup}>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={styles.input}
            placeholder="Enter document title"
          />
          {titleError && <span style={styles.error}>{titleError}</span>}
        </div>

        {/* Topic Selection */}
        <div style={styles.formGroup}>
          <label htmlFor="topic">Select Topic:</label>
          <select
            id="topic"
            value={selectedTopicId}
            onChange={(e) => setSelectedTopicId(e.target.value)}
            style={styles.select}
          >
            <option value="">-- Select a topic --</option>
            {topics.map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.name}
              </option>
            ))}
          </select>
          {topicError && <span style={styles.error}>{topicError}</span>}
        </div>

        {/* File Input */}
        <div style={styles.formGroup}>
          <label htmlFor="file">Select PDF File:</label>
          <input
            type="file"
            id="file"
            accept="application/pdf"
            onChange={handleFileChange}
            style={styles.fileInput}
          />
          {fileName && <p>Selected File: {fileName}</p>}
          {fileError && <span style={styles.error}>{fileError}</span>}
        </div>

        {/* Submit Button */}
        <button type="submit" style={styles.button} disabled={uploading}>
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </form>
    </div>
  );
};

export default UploadPage;

// Inline styles for simplicity. You can refactor this to use CSS or a CSS-in-JS library.
const styles = {
  container: {
    maxWidth: "500px",
    margin: "50px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)"
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  formGroup: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column"
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc"
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc"
  },
  fileInput: {
    padding: "10px",
    fontSize: "16px"
  },
  button: {
    padding: "12px",
    fontSize: "16px",
    backgroundColor: "#F1C950",
    color: "#000",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  error: {
    color: "red",
    fontSize: "14px",
    marginTop: "5px"
  },
  success: {
    color: "green",
    fontSize: "14px",
    marginBottom: "15px"
  }
};
