import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import '../assets/css/GoldShine.css';
import '../assets/css/SidebarUngrouped.css';
import transpVector from "../assets/img/transp_vector.png"; // Import the transparent logo
import { logout } from '../services/authService'; // Import the logout function
import {
  ungroupedMenuItem,
  collegeResources,
  applicationManagement,
  aiAssistance,
  socialFeatures,
  adminFeatures,
  supportFeatures
} from "../data/Menu";

export default class Sidebar extends Component {
  toggleFooterMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  }

  render() {
    let user;
    try {
      user = JSON.parse(localStorage.getItem('user'));
    } catch (e) {
      user = null;
    }

    const userType = user ? user.user_type : 0;
    const fullName = user ? `${user.name} ${user.surname}` : 'Guest';
    const avatarUrl = user && user.avatar ? `https://api.youelite.net/storage/${user.avatar}` : userAvatar;

    const getUserBadge = (userType) => {
      switch (userType) {
        case 2:
          return (
            <i className="ri-vip-crown-line"></i>
          );
        case 100:
          return (
            <img src={transpVector} alt="Admin" style={{ width: '1em', height: '1em', marginRight: '0.25rem' }} />
          );
        case 90:
          return (
            <img src={transpVector} alt="Sales" style={{ width: '1em', height: '1em', marginRight: '0.25rem' }} />
          );
        case 80:
          return (
            <img src={transpVector} alt="Customer Support" style={{ width: '1em', height: '1em', marginRight: '0.25rem' }} />
          );
        case 70:
          return (
            <i className="ri-vip-crown-line"></i>
          );
          case 1:
            return (
              <span
                className="shine"
                onClick={() => window.location.href = '/plans'}
              >
                Upgrade to Premium
              </span>
            );
        default:
          return null;
      }
    };

    return (
      <div id="sidebar" className="sidebar">
        <div className="sidebar-header">
          <Link to="/" className="sidebar-logo">YouElite</Link>
        </div>
        <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
          <SidebarMenu userType={userType} onUpdateSize={() => this._scrollBarRef.updateScroll()} />
        </PerfectScrollbar>
        <div className="sidebar-footer">
          <div className="sidebar-footer-top">
            <div className="sidebar-footer-thumb">
              <img src={avatarUrl} alt="User Avatar" />
            </div>
            <div className="sidebar-footer-body">
              <h6><Link to="/my-profile">{fullName}</Link></h6>
              <p className="d-flex align-items-center">
                {user && getUserBadge(user.user_type)}
                {user && user.user_type === 2 && ' Premium Member'}
                {user && user.user_type === 100 && ' Administration'}
                {user && user.user_type === 90 && ' Sales'}
                {user && user.user_type === 80 && ' Customer Support'}
                {user && user.user_type === 70 && ' Moderator'}
              </p>
            </div>
            <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
          </div>
          <div className="sidebar-footer-menu">
            <nav className="nav">
              <Link to=""><i className="ri-edit-2-line" ></i> Edit Profile</Link>
              <Link to="/my-profile"><i className="ri-profile-line"></i> View Profile</Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to="/faq"><i className="ri-question-line"></i> Help Center</Link>
              <Link to=""><i className="ri-lock-line" ></i> Privacy Settings</Link>
              <Link to="/settings"><i className="ri-user-settings-line"></i> Account Settings</Link>
              <Link onClick={logout} to="/signin"><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

class SidebarMenu extends Component {

  // Function to filter groups and their menu items based on user type
  filterGroups = (groups, userType) => {
    return groups.filter(group => {
      // First filter the group itself
      if (group.allowedUserTypes.includes(userType)) {
        // Then filter the individual items in the group
        group.items = group.items.filter(item => item.allowedUserTypes.includes(userType));
        return true; // Keep the group if it matches
      }
      return false; // Exclude the group if it doesn't match
    });
  }

  populateMenu = (groups) => {
    const { userType } = this.props;

    // Filter the groups based on the user type
    const filteredGroups = this.filterGroups(groups, userType);

    return filteredGroups.map((group, groupKey) => (
      <div id="grouped-sidebar" key={groupKey} className="nav-group show">
        <div className="nav-label" onClick={this.toggleMenu}>{group.label}</div>
        <ul className="nav nav-sidebar">
          {group.items.map((item, itemKey) => (
            <li key={itemKey} className="nav-item">
              <NavLink to={item.link} className="nav-link"><i className={item.icon}></i> <span>{item.label}</span></NavLink>
            </li>
          ))}
        </ul>
      </div>
    ));
  }

  // Toggle menu group
  toggleMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest('.nav-group');
    parent.classList.toggle('show');
    this.props.onUpdateSize();
  }

  render() {
    return (
      <React.Fragment>
        <ul className="nav ungroupedside">
          <li className="nav-item">
            <NavLink to={ungroupedMenuItem.link} className="nav-link">
              <i className={ungroupedMenuItem.icon}></i> <span>{ungroupedMenuItem.label}</span>
            </NavLink>
          </li>
        </ul>
        {this.populateMenu([collegeResources, applicationManagement, aiAssistance, socialFeatures, adminFeatures, supportFeatures])}
      </React.Fragment>
    );
  }
}

window.addEventListener("click", function (e) {
  // Close sidebar footer menu when clicked outside of it
  let tar = e.target;
  let sidebar = document.querySelector(".sidebar");
  if (!tar.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("sidebar-skin");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});