import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const CollegePreferences = () => {
  const [formData, setFormData] = useState({
    collegeSize: '',
    institutionType: '',
    specificPrograms: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = localStorage.getItem('user');
      if (!user) {
        setMessage('No authentication token found');
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(user);

      try {
        const response = await axios.get('https://api.youelite.net/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        const data = response.data;
        setFormData({
          collegeSize: data.collegeSize || '',
          institutionType: data.institutionType || '',
          specificPrograms: data.specificPrograms || ''
        });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setMessage('Failed to load college preferences');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = localStorage.getItem('user');
    if (!user) {
      setMessage('No authentication token found');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.put('https://api.youelite.net/api/user', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('College preferences updated successfully');
      setShowModal(true); // Show the modal after form submission
    } catch (error) {
      console.error('Failed to update college preferences:', error);
      setMessage('Failed to update college preferences');
    }
  };

  const handleTakeTour = () => {
    setShowModal(false);
    navigate('/tour-1'); // Redirect to the first step of the tour
  };

  const handleGoToDashboard = () => {
    setShowModal(false);
    navigate('/'); // Redirect to the dashboard
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ maxWidth: '700px', margin: 'auto' }}>
        <Card.Header>
          <Link to="/" className="header-logo mb-4">YouElite</Link>
          <Card.Title>College Preferences</Card.Title>
          <Card.Text>Tell us about your college preferences.</Card.Text>
        </Card.Header>
        <Card.Body>
          {message && <div className="alert alert-info">{message}</div>}
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Form.Label>Desired College Size</Form.Label>
              <Form.Control 
                as="select" 
                name="collegeSize"
                value={formData.collegeSize} 
                onChange={handleChange}
                required
              >
                <option value="">Select college size</option>
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
              </Form.Control>
            </div>
            <div className="mb-3">
              <Form.Label>Preference for Public or Private Institutions</Form.Label>
              <Form.Control 
                as="select" 
                name="institutionType"
                value={formData.institutionType} 
                onChange={handleChange}
                required
              >
                <option value="">Select institution type</option>
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </Form.Control>
            </div>
            <div className="mb-3">
              <Form.Label>Interests in Specific Programs or Facilities</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter specific programs or facilities (e.g., Sports, Arts, Research Opportunities)" 
                name="specificPrograms"
                value={formData.specificPrograms} 
                onChange={handleChange} 
                required 
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Next</Button>
          </Form>
          <Button variant="link" onClick={() => navigate('/')} style={{color: '#007bff', textDecoration: 'underline', marginTop: '10px'}}>Skip</Button>
        </Card.Body>
      </Card>

      {/* Modal for Quick Tour or Dashboard */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome to YouElite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to take a quick tour of the platform or go directly to your dashboard?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleGoToDashboard}>
            Go to Dashboard
          </Button>
          <Button variant="primary" onClick={handleTakeTour}>
            Take Quick Tour
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CollegePreferences;
