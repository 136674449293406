import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spinner, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import '../assets/css/ApplicationAnalyzer.css';

const ApplicationAnalyzer = () => {
  useEffect(() => {
    document.title = "Application Analyzer | YouElite";
  }, []);

  const [skin, setSkin] = useState("");
  const [analysisStarted, setAnalysisStarted] = useState(false);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isCheckingAnalysis, setIsCheckingAnalysis] = useState(true);
  const [isRedoingAnalysis, setIsRedoingAnalysis] = useState(false);

  // Retrieve the token from localStorage under 'user' and 'token'
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user ? user.token : null;

  useEffect(() => {
    const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
    setSkin(currentSkin);
  }, []);

  useEffect(() => {
    // Check if user has a saved analysis
    const fetchSavedAnalysis = async () => {
      if (!token) {
        console.error('No auth token found');
        setIsCheckingAnalysis(false);
        return;
      }
      try {
        const response = await fetch('https://api.youelite.net/api/saved-application-analysis', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data && Object.keys(data).length > 0) {
            setAnalysisResult(data);
            setAnalysisStarted(true);

            // Fetch user data
            const userResponse = await fetch('https://api.youelite.net/api/user', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            });
            if (userResponse.ok) {
              const userData = await userResponse.json();
              setUserData(userData);
            } else {
              console.error('Failed to fetch user data');
            }
          }
        } else {
          console.error('Failed to fetch saved analysis');
        }
      } catch (error) {
        console.error('Error fetching saved analysis:', error);
      } finally {
        setIsCheckingAnalysis(false);
      }
    };
    fetchSavedAnalysis();
  }, [token]);

  const handleStartAnalysis = () => {
    setAnalysisLoading(true);

    const fetchAnalysisData = async () => {
      if (!token) {
        console.error('No auth token found');
        setAnalysisLoading(false);
        return;
      }
      try {
        const response = await fetch('https://api.youelite.net/api/analyze-application', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAnalysisResult(data);
          setAnalysisStarted(true);

          // Fetch user data
          const userResponse = await fetch('https://api.youelite.net/api/user', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUserData(userData);
          } else {
            console.error('Failed to fetch user data');
          }
        } else {
          console.error('Failed to fetch analysis data');
        }
      } catch (error) {
        console.error('Error fetching analysis data:', error);
      } finally {
        setAnalysisLoading(false);
        setIsRedoingAnalysis(false); // Reset redo analysis state
      }
    };
    fetchAnalysisData();
  };

  const handleRedoAnalysis = () => {
    setIsRedoingAnalysis(true);
    const redoAnalysis = async () => {
      if (!token) {
        console.error('No auth token found');
        setIsRedoingAnalysis(false);
        return;
      }
      try {
        // Delete the existing analysis
        const deleteResponse = await fetch('https://api.youelite.net/api/saved-application-analysis', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (deleteResponse.ok) {
          // Re-analyze the application
          handleStartAnalysis();
        } else {
          console.error('Failed to delete saved analysis');
          setIsRedoingAnalysis(false);
        }
      } catch (error) {
        console.error('Error during redo analysis:', error);
        setIsRedoingAnalysis(false);
      }
    };
    redoAnalysis();
  };

  // Determine score color
  const determineScoreColor = (score) => {
    if (score < 30) return "#FF0000"; // Red
    if (score >= 30 && score <= 75) return "#FFCC00"; // Yellow
    return "#4CAF50"; // Green
  };

  const performanceData = {
    below: { label: "Below Average", color: "#FF0000" }, // Red
    on: { label: "On Average", color: "#FFCC00" }, // Yellow
    above: { label: "Above Average", color: "#4CAF50" }, // Green
  };

  const performanceLevel = analysisResult ? analysisResult.activitiesAssessment : "on";
  const { label, color } = performanceData[performanceLevel] || performanceData['on'];

  const getSliderPosition = (score, minScore, maxScore) => {
    return ((score - minScore) / (maxScore - minScore)) * 100;
  };

  const getSectionData = (score, minScore, maxScore) => {
    const percentage = ((score - minScore) / (maxScore - minScore)) * 100;

    if (percentage <= 20) {
      return { color: "#FFB3B3", text: "Needs Improvement", section: "red" };
    } else if (percentage <= 50) {
      return { color: "#FFE680", text: "Average", section: "yellow" };
    } else {
      return { color: "#A8E6A2", text: "Excellent", section: "green" };
    }
  };

  const renderSegmentedBar = (score, minScore, maxScore) => {
    const sliderPosition = getSliderPosition(score, minScore, maxScore);
    const sectionData = getSectionData(score, minScore, maxScore);

    return (
      <>
        <div style={{ position: "relative", height: "10px", width: "100%" }}>
          {/* Segmented bar */}
          <div style={{ display: "flex", height: "100%", backgroundColor: "#ddd", borderRadius: "5px" }}>
            <div style={{ width: "20%", backgroundColor: "#FFB3B3", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}></div>
            <div style={{ width: "30%", backgroundColor: "#FFE680" }}></div>
            <div style={{ width: "50%", backgroundColor: "#A8E6A2", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}></div>
          </div>

          {/* Slider (circular marker) */}
          <div
            style={{
              position: "absolute",
              top: "-6px",
              left: `${sliderPosition}%`,
              transform: "translateX(-50%)",
              width: "16px",
              height: "16px",
              backgroundColor: "#000",
              borderRadius: "50%",
              border: "2px solid #fff",
            }}
          ></div>
        </div>

        {/* Dynamic text below the bar */}
        <p className="mt-3" style={{ color: sectionData.color, fontSize: "14px", fontWeight: "bold" }}>
          {sectionData.text}
        </p>
      </>
    );
  };

  const renderScoreSection = (title, score, minScore, maxScore, percentile, comment) => {
    if (score === null || score === undefined) {
      return (
        <Col md={4} className="text-center">
          <h6>{title}</h6>
          <h2 className="display-4">N/A</h2>
          <span className="badge bg-secondary mb-2">No score available</span>
        </Col>
      );
    }

    return (
      <Col md={4} className="text-center">
        <h6 style={{ fontWeight: "500" }}>{title}</h6>
        <h1 style={{ fontSize: "48px", marginBottom: "10px" }}>{score}</h1>
        <span style={{ fontSize: "14px", padding: "5px 10px", backgroundColor: "#e0e0e0", color: "#000", marginBottom: "15px" }} className="badge">
          {percentile ? `${percentile}th Percentile` : 'Percentile N/A'}
        </span>
        {renderSegmentedBar(score, minScore, maxScore)}
        <small style={{ fontSize: "12px" }}>{comment}</small>
      </Col>
    );
  };

  // Prepare activities data
  const activities = analysisResult ? analysisResult.activities.map(activity => {
    return {
      title: activity.name,
      description: activity.description,
      strength: parseInt(activity.strength),
      comment: analysisResult.activityComments[activity.name] || '',
    };
  }) : [];

  // Test scores from userData
  const testScores = userData ? {
    readingWritingScore: userData.sat_reading_writing_score,
    mathScore: userData.sat_math_score,
    totalScore: userData.sat_score,
    // Percentiles can be set to null or computed if data is available
    readingWritingPercentile: null,
    mathPercentile: null,
    totalPercentile: null,
  } : null;

  // Loading state while checking for saved analysis
  if (isCheckingAnalysis) {
    return (
      <div className="loading text-center" style={{ marginTop: '20%' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Checking your analysis...</span>
        </Spinner>
        <p className="mt-3">Checking your analysis...</p>
      </div>
    );
  }

  // Calculate circle properties when analysisResult is available
  let circleProps = {};
  if (analysisResult) {
    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (analysisResult.score / 100) * circumference;
    circleProps = {
      strokeDasharray: `${circumference} ${circumference}`,
      strokeDashoffset,
    };
  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          {!analysisStarted ? (
            // Start Analysis Page
            <div className="start-analysis text-center">
              <h2>Welcome to the Application Analyzer</h2>
              <p>Get a comprehensive analysis of your application and see how you stand among other applicants.</p>
              <button className="btn btn-primary btn-lg mt-4" onClick={handleStartAnalysis}>
                Start Analysis
              </button>
            </div>
          ) : analysisLoading || isRedoingAnalysis ? (
            // Loading Indicator
            <div className="loading text-center">
              <Spinner animation="border" role="status" className="mt-5">
                <span className="visually-hidden">Loading your analysis...</span>
              </Spinner>
              <p className="mt-3">{isRedoingAnalysis ? 'Recalculating your analysis...' : 'Loading your analysis...'}</p>
            </div>
          ) : analysisResult ? (
            // Analysis Results Page
            <>
              <h4 className="main-title mb-4">Application Analyzer</h4>

              <Row className="g-4">
                <Col md={6} lg={4}>
                  <Card className="app-analyzer-score-card" style={{ borderRadius: '10px', backgroundColor: '#ffffff', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)' }}>
                    <Card.Body className="d-flex flex-column align-items-center">
                      <div className="test-score-circle">
                        <svg viewBox="0 0 100 100" className="app-analyzer-score-circle">
                          <circle cx="50" cy="50" r="45" fill="#f0f0f0" />
                          <circle
                            cx="50"
                            cy="50"
                            r="45"
                            fill="transparent"
                            stroke={determineScoreColor(analysisResult.score)}
                            strokeWidth="10"
                            transform="rotate(-90 50 50)"
                            strokeLinecap="round"
                            {...circleProps}
                          />
                          <text x="50" y="50" textAnchor="middle" dy=".3em" fontSize="30" fontWeight="bold">
                            {analysisResult.score}
                          </text>
                          <text x="50" y="70" textAnchor="middle" fontSize="12">
                            Score
                          </text>
                        </svg>
                      </div>
                      <div className="app-analyzer-nps-text">
                        {analysisResult.score >= 75 ? 'Excellent' : analysisResult.score >= 30 ? 'Good' : 'Poor'}
                      </div>
                    </Card.Body>
                  </Card>

                  {/* Redo Analysis Button */}
                  <div className="text-center mt-3">
                    <Button variant="secondary" size="md" onClick={handleRedoAnalysis}>
                      Redo Analysis
                    </Button>
                  </div>
                </Col>

                <Col md={6} lg={8}>
                  <Card
                    className="card-one"
                    style={{
                      borderRadius: '10px',
                      backgroundColor: '#ffffff',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
                    }}
                  >
                    <Card.Body>
                      <h5 className="card-title mb-3">General Overview</h5>
                      <p>{analysisResult.overview}</p>
                      <h6>Pros:</h6>
                      <ul>
                        {analysisResult.pros && analysisResult.pros.map((pro, index) => (
                          <li key={index}>{pro}</li>
                        ))}
                      </ul>
                      <h6>Areas for Improvement:</h6>
                      <ul>
                        {analysisResult.cons && analysisResult.cons.map((con, index) => (
                          <li key={index}>{con}</li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {testScores && (
                <Row className="g-4 mt-3">
                  <Col md={12}>
                    <Card
                      className="card-one"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
                      }}
                    >
                      <Card.Body>
                        <h5 className="card-title text-center" style={{ fontWeight: '500' }}>
                          Test Scores
                        </h5>

                        <Row className="mb-4">
                          {/* Reading and Writing Section */}
                          {renderScoreSection(
                            'Your Evidence-Based Reading and Writing Score',
                            testScores.readingWritingScore,
                            200,
                            800,
                            testScores.readingWritingPercentile,
                            analysisResult.testScoreComment || 'You are on track for college readiness.'
                          )}

                          {/* Total Score Section */}
                          {renderScoreSection(
                            'Your Total Score',
                            testScores.totalScore,
                            400,
                            1600,
                            testScores.totalPercentile,
                            'Keep in mind, the PSAT/NMSQT and SAT are on the same scale.'
                          )}

                          {/* Math Section */}
                          {renderScoreSection(
                            'Your Math Score',
                            testScores.mathScore,
                            200,
                            800,
                            testScores.mathPercentile,
                            analysisResult.testScoreComment || 'You are on track for college readiness.'
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}

              <Row className="g-4 mt-4">
                <Col md={12}>
                  <Card
                    className="card-one"
                    style={{
                      borderRadius: '10px',
                      backgroundColor: '#ffffff',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-between">
                        <h5 className="card-title">Extracurriculars</h5>
                        <span
                          style={{
                            backgroundColor: color,
                            padding: '6px 12px',
                            borderRadius: '5px',
                            color: '#fff',
                            display: 'inline-block',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            minWidth: '120px',
                          }}
                        >
                          {label}
                        </span>
                      </div>
                      <table className="table table-hover mt-3">
                        <thead>
                          <tr>
                            <th>ACTIVITY</th>
                            <th>STRENGTH</th>
                            <th className="strength-column">STRENGTH BAR</th>
                            <th>COMMENT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activities.map((activity, index) => {
                            const strengthValue = activity.strength;

                            return (
                              <tr key={index}>
                                <td>{activity.title}</td>
                                <td>{strengthValue}</td>
                                <td className="strength-column">
                                  <div className="strength-bar">
                                    <div
                                      className="strength-fill"
                                      style={{ width: `${(strengthValue / 5) * 100}%` }}
                                    ></div>
                                  </div>
                                </td>
                                <td>{activity.comment}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="g-4 mt-4">
                <Col md={12}>
                  <Card
                    className="card-one"
                    style={{
                      borderRadius: '10px',
                      backgroundColor: '#ffffff',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.13)',
                    }}
                  >
                    <Card.Body>
                      <h5 className="card-title">Major Compatibility</h5>
                      {analysisResult.likedMajorsAnalysis &&
                        Object.entries(analysisResult.likedMajorsAnalysis).map(([major, analysis], index) => (
                          <div key={index} className="major-item mt-3">
                            <h6>{major}</h6>
                            <p>{analysis}</p>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            // Handle unexpected state
            <div className="error text-center">
              <p>An error occurred while loading your analysis. Please try again.</p>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ApplicationAnalyzer;
