import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import { register } from "../services/authService";

export default function Signup() {

  useEffect(() => {
    document.title = "Signup | YouElite";
  }, []);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    try {
      // Call the register function and get the full response
      const response = await fetch('https://api.youelite.net/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          name,
          surname,
          email,
          password,
          password_confirmation: confirmPassword,
        }),
      });
  
      // If the response status is 403, handle the redirection
      if (response.status === 403) {
        const data = await response.json();
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
          return; // Stop further execution after redirection
        }
      }
  
      // If registration is successful, process the response normally
      if (response.ok) {
        const data = await response.json();
        setSuccess('Registration successful! Redirecting to email verification...');
        localStorage.setItem('user', JSON.stringify(data));
  
        ReactGA.event('sign_up', {
          method: 'Email', // Optional: specify the method of sign-up
          user_id: data.id, // Optional: use the user's ID from the response if available
        });
  
        // Redirect to the verify page after 1 second
        setTimeout(() => {
          navigate('/verify');
        }, 1000); 
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed. Please check your details and try again.');
      }
    } catch (error) {
      setError(error.message);
      console.error('Registration error:', error.message); // Log error details
    }
  };
  
  

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">YouElite</Link>
          <Card.Title>Sign Up</Card.Title>
          <Card.Text>It's free to signup and only takes a minute.</Card.Text>
        </Card.Header>
        <Card.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your first name" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Surname</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your surname" 
                value={surname} 
                onChange={(e) => setSurname(e.target.value)} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter your email address" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter your password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Confirm your password" 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                required 
              />
            </div>
            <div className="mb-4">
            <small>By clicking <strong>Create Account</strong> below, you agree to our <a href="https://youelite.net/terms-conditions/" target="_blank">terms of service</a> and privacy statement.</small>
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Create Account</Button>
          </Form>

          <div className="divider" style={{display:"none"}}><span>or sign up using</span></div>

          <Row className="gx-2" style={{display:"none"}}>
            <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
            <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          Already have an account? <Link to="/signin">Sign In</Link>
        </Card.Footer>
      </Card>
    </div>
  );
}
