import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import axios from "axios";
import ReactGA from "react-ga4";
import { loadStripe } from '@stripe/stripe-js';
import '../assets/css/PricingToggle.css'

export default function Pricing() {
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [userType, setUserType] = useState(null);
  const [subtitle, setSubtitle] = useState("");
  const [pricingPeriod, setPricingPeriod] = useState("monthly");
  const [annualPrices, setAnnualPrices] = useState({});

  useEffect(() => {
    const subtitles = [
      "We promise, your coffee budget is still safe.",
      "These prices? Practically a student discount on a student discount.",
      "Prices so low, you'll wonder if we skipped math class.",
      "Priced so low, it’s almost like we’re paying you.",
      "Affordable enough to leave room for your late-night pizza fund."
    ];

    const randomSubtitle = subtitles[Math.floor(Math.random() * subtitles.length)];
    setSubtitle(randomSubtitle);

    const authToken = localStorage.getItem("token");

    const storedUser = localStorage.getItem("user");
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;

    // Updated logic for setting userType
    if (parsedUser) {
      if (parsedUser.user_type === 2 && parsedUser.active_free_trial) {
        // Treat as user_type = 1 if user_type = 2 and active_free_trial = true
        setUserType(1);
      } else {
        // Otherwise, use the actual user_type
        setUserType(parsedUser.user_type);
      }
    }

    const fetchPlans = async () => {
      try {
        const response = await axios.get("/plans", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const publicPlans = response.data.filter(plan => plan.public_plan === "yes");

        let consolidatedFeatures = {};
        let annualPricesObj = {};
        publicPlans.forEach(plan => {
          const planFeatures = JSON.parse(plan.features || '[]');
          planFeatures.forEach(feature => {
            if (!consolidatedFeatures[feature.title]) {
              consolidatedFeatures[feature.title] = {};
            }
            consolidatedFeatures[feature.title][plan.id] = feature.description;
          });
          annualPricesObj[plan.id] = plan.annual_price;
        });

        setPlans(publicPlans);
        setFeatures(consolidatedFeatures);
        setAnnualPrices(annualPricesObj);
        ReactGA.event('view_item_list', {
          items: publicPlans.map(plan => ({
            item_name: plan.name,
            item_id: plan.id.toString(),
            price: parseFloat(getDisplayedPrice(plan)),
          })),
        });
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    fetchPlans();
  }, []);

  const handlePlanChange = async (plan) => {
    const authToken = localStorage.getItem("token");

    ReactGA.event('select_item', {
      items: [{
        item_name: plan.name,
        item_id: plan.id.toString(),
        price: parseFloat(getDisplayedPrice(plan)),
      }],
    });

    try {
        const response = await axios.post('https://api.youelite.net/api/create-checkout-session', {
            plan_id: plan.stripe_price_id,
        }, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            }
        });

        const sessionId = response.data.id;
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        
        ReactGA.event('begin_checkout', {
          currency: 'USD',  // Replace with your currency if different
          value: parseFloat(getDisplayedPrice(plan)),
          items: [{
            item_name: plan.name,
            item_id: plan.id.toString(),
            price: parseFloat(getDisplayedPrice(plan)),
          }],
        });

        stripe.redirectToCheckout({ sessionId });
    } catch (error) {
        console.error("Error changing plan:", error);
        alert('There was an issue changing your plan. Please try again.');
    }
  };

  const columnWidth = plans.length > 0 ? 100 / (plans.length + 1) : 100;

  const getDisplayedPrice = (plan) => {
    if (pricingPeriod === "yearly") {
      return parseFloat(annualPrices[plan.id] || 0).toFixed(2);
    }
    return parseFloat(plan.price).toFixed(2);
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-pricing p-4 p-lg-5">
        <h1 className="pricing-title mb-2">Choose a plan that's right for your future.</h1>
        <p className="pricing-text mb-4">{subtitle}</p>

        {/* Centered Toggle Switch */}
        <div className="custom-pricing-toggle-wrapper" style={{ display: 'none' }}        >
          <div className="custom-pricing-toggle">
            <span
              onClick={() => setPricingPeriod("monthly")}
              className={pricingPeriod === "monthly" ? "active" : ""}
            >
              Pay Monthly
            </span>
            <label className="custom-toggle-switch">
              <input
                type="checkbox"
                checked={pricingPeriod === "yearly"}
                onChange={() => setPricingPeriod(pricingPeriod === "monthly" ? "yearly" : "monthly")}
              />
              <span className="custom-slider"></span>
            </label>
            <span
              onClick={() => setPricingPeriod("yearly")}
              className={pricingPeriod === "yearly" ? "active" : ""}
            >
              Pay Yearly
            </span>
          </div>
          <small className="custom-pricing-discount-note">Trust us, you'll want to get the yearly subscription</small>
        </div>

        <Table className="table-pricing" responsive>
          <thead>
            <tr>
              <th style={{ width: `${columnWidth}%` }}></th>
              {plans.map(plan => (
                <th
                  key={plan.id}
                  style={{ width: `${columnWidth}%` }}
                  className={userType === plan.id ? 'selected' : ''}
                >
                  <span>{plan.name}</span>
                  <div className="pricing-price">
                    ${getDisplayedPrice(plan)}
                    <small>/{pricingPeriod === "yearly" ? "year" : "month"}</small>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(features).map((featureTitle, index) => (
              <tr key={index}>
                <td>{featureTitle}</td>
                {plans.map(plan => (
                  <td
                    key={plan.id}
                    className={userType === plan.id ? 'selected' : ''}
                  >
                    {features[featureTitle][plan.id] === "yes" ? (
                      <i className="ri-check-fill"></i>
                    ) : features[featureTitle][plan.id] === "no" ? (
                      <i className="ri-close-fill" style={{ color: "#6c757d" }}></i>
                    ) : (
                      <div>{features[featureTitle][plan.id]}</div>
                    )}
                  </td>
                ))}
              </tr>
            ))}

            <tr>
              <td></td>
              {plans.map(plan => (
                <td
                  key={plan.id}
                  className={userType === plan.id ? 'selected' : ''}
                >
                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={() => handlePlanChange(plan)}
                    disabled={userType === plan.id}
                  >
                    {userType === plan.id ? "Current Plan" : "Choose Plan"}
                  </Button>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        <Footer className="footer" />
      </div>
    </React.Fragment>
  );
}