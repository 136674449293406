import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const SATScoreForm = () => {
  const [formData, setFormData] = useState({
    sat_score: '', // Total SAT score
    sat_math_score: '', // SAT Math score
    sat_reading_writing_score: '' // SAT Evidence-Based Reading & Writing score
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // Modal state for tour/dashboard option
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    const fetchUserData = async () => {
      const user = localStorage.getItem('user');
      if (!user) {
        setMessage('No authentication token found');
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(user);

      try {
        const response = await axios.get('https://api.youelite.net/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        const data = response.data;
        setFormData({
          sat_score: data.sat_score || '', // Total SAT score
          sat_math_score: data.sat_math_score || '', // SAT Math score
          sat_reading_writing_score: data.sat_reading_writing_score || '' // SAT Reading & Writing score
        });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setMessage('Failed to load SAT scores');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = localStorage.getItem('user');
    if (!user) {
      setMessage('No authentication token found');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.put('https://api.youelite.net/api/user', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('SAT scores updated successfully');
      setShowModal(true); // Show modal after form submission
    } catch (error) {
      console.error('Failed to update SAT scores:', error);
      setMessage('Failed to update SAT scores');
    }
  };

  const handleTakeTour = () => {
    setShowModal(false);
    navigate('/tour-1'); // Redirect to the first step of the tour
  };

  const handleGoToDashboard = () => {
    setShowModal(false);
    navigate('/'); // Redirect to the dashboard
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ maxWidth: '700px', margin: 'auto' }}>
        <Card.Header>
          <Link to="/" className="header-logo mb-4">YouElite</Link>
          <Card.Title>SAT Scores</Card.Title>
          <Card.Text>Please enter your SAT total score and section scores. This information is essential for the platform to function correctly. If you haven't taken the SAT yet, please provide your best estimate.</Card.Text>
        </Card.Header>
        <Card.Body>
          {message && <div className="alert alert-info">{message}</div>}
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Form.Label>SAT Total Score (400-1600)</Form.Label>
              <Form.Control 
                type="number" 
                placeholder="Enter your total SAT score" 
                name="sat_score"
                value={formData.sat_score} 
                onChange={handleChange} 
                min="400" 
                max="1600" 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>SAT Math Score (200-800)</Form.Label>
              <Form.Control 
                type="number" 
                placeholder="Enter your SAT Math score" 
                name="sat_math_score"
                value={formData.sat_math_score} 
                onChange={handleChange} 
                min="200" 
                max="800" 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>SAT Evidence-Based Reading & Writing Score (200-800)</Form.Label>
              <Form.Control 
                type="number" 
                placeholder="Enter your SAT Reading & Writing score" 
                name="sat_reading_writing_score"
                value={formData.sat_reading_writing_score} 
                onChange={handleChange} 
                min="200" 
                max="800" 
                required 
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Save and Continue</Button>
          </Form>
          <Button variant="link" onClick={() => navigate('/')} style={{color: '#007bff', textDecoration: 'underline', marginTop: '10px'}}>Skip</Button>
        </Card.Body>
      </Card>

      {/* Modal for Quick Tour or Dashboard */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome to YouElite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to take a quick tour of the platform or go directly to your dashboard?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleGoToDashboard}>
            Go to Dashboard
          </Button>
          <Button variant="primary" onClick={handleTakeTour}>
            Take Quick Tour
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SATScoreForm;
