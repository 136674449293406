import React, { useState, useEffect, useCallback } from 'react';
import { Card, Form, Button, Table, Toast } from 'react-bootstrap';
import axios from 'axios';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);




export default function MajorMatchmaker() {

  useEffect(() => {
    document.title = "Major Matchmaker | YouElite";
  }, []);


  
  const [step, setStep] = useState('loading');
  const [profile, setProfile] = useState({});
  const [interestQuestions, setInterestQuestions] = useState([]);
  const [interestAnswers, setInterestAnswers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [results, setResults] = useState({
    result: [
      { area: 'Realistic', score: 0, description: '' },
      { area: 'Investigative', score: 0, description: '' },
      { area: 'Artistic', score: 0, description: '' },
      { area: 'Social', score: 0, description: '' },
      { area: 'Enterprising', score: 0, description: '' },
      { area: 'Conventional', score: 0, description: '' }
    ]
  });
  const [recommendations, setRecommendations] = useState([]);
  const [isRefined, setIsRefined] = useState(false);
  const [userFeedback, setUserFeedback] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [likedMajors, setLikedMajors] = useState([]);
  const [dislikedMajors, setDislikedMajors] = useState([]);


  
  
  const showToastNotification = (message) => {
    setToastMessage(message);
    setShowToast(true);
  
    // Hide the toast after a few seconds
    setTimeout(() => {
      setShowToast(false);
    }, 3000);  // 3 seconds
  };
  
  
  const handleSurveyAgain = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    
    if (!user || !user.token) {
      console.error("User not authenticated");
      return;
    }
  
    try {
      await axios.delete('https://api.youelite.net/api/user-profile', {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
  
      setStep('interestAssessment');
      setInterestAnswers({});  
      setCurrentPage(1);       
  
    } catch (error) {
      console.error('Error resetting the survey:', error);
    }
  };
  
  const saveMajorPreference = async (major, action) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error("User not authenticated");
      return;
    }
  
    try {
      if (action === 'like') {
        if (likedMajors.includes(major)) {
          // Remove the major from liked_majors
          setLikedMajors((prevLikedMajors) => prevLikedMajors.filter((m) => m !== major));
  
          // Send a request to remove the major from the liked_majors in the backend
          await axios.post('https://api.youelite.net/api/remove-major', 
            { major, action: 'like' },
            {
              headers: {
                Authorization: `Bearer ${user.token}`
              }
            }
          );
  
          // Show removal notification
          showToastNotification(`You unliked ${major}`);
  
        } else {
          // Add the major to liked_majors and remove from disliked_majors
          setLikedMajors((prevLikedMajors) => [...prevLikedMajors, major]);
          setDislikedMajors((prevDislikedMajors) => prevDislikedMajors.filter((m) => m !== major));
  
          // Send a request to add the major to liked_majors in the backend
          await axios.post('https://api.youelite.net/api/save-major', 
            { major, action: 'like' },
            {
              headers: {
                Authorization: `Bearer ${user.token}`
              }
            }
          );
  
          // Show addition notification
          showToastNotification(`You liked ${major}`);
        }
      } else if (action === 'dislike') {
        if (dislikedMajors.includes(major)) {
          // Remove the major from disliked_majors
          setDislikedMajors((prevDislikedMajors) => prevDislikedMajors.filter((m) => m !== major));
  
          // Send a request to remove the major from the disliked_majors in the backend
          await axios.post('https://api.youelite.net/api/remove-major', 
            { major, action: 'dislike' },
            {
              headers: {
                Authorization: `Bearer ${user.token}`
              }
            }
          );
  
          // Show removal notification
          showToastNotification(`You undisliked ${major}`);
  
        } else {
          // Add the major to disliked_majors and remove from liked_majors
          setDislikedMajors((prevDislikedMajors) => [...prevDislikedMajors, major]);
          setLikedMajors((prevLikedMajors) => prevLikedMajors.filter((m) => m !== major));
  
          // Send a request to add the major to disliked_majors in the backend
          await axios.post('https://api.youelite.net/api/save-major', 
            { major, action: 'dislike' },
            {
              headers: {
                Authorization: `Bearer ${user.token}`
              }
            }
          );
  
          // Show addition notification
          showToastNotification(`You disliked ${major}`);
        }
      }
    } catch (error) {
      console.error('Error saving major preference:', error);
    }
  };
  
  
  
  
  
  const checkUserProfile = useCallback(async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        console.error("User not authenticated");
        return;
      }
  
      const response = await axios.get('https://api.youelite.net/api/user-profile', {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
  
      console.log(response.data); // Log the entire response to inspect its structure
  
      if (response.data.hasProfile) {
        setResults({ result: response.data.results.result });
  
        // Safely access liked_majors and disliked_majors
        const likedMajors = response.data.user?.liked_majors ? JSON.parse(response.data.user.liked_majors) : [];
        const dislikedMajors = response.data.user?.disliked_majors ? JSON.parse(response.data.user.disliked_majors) : [];
  
        setLikedMajors(likedMajors);
        setDislikedMajors(dislikedMajors);
  
        await fetchRecommendations(response.data.results);
        setStep('results');
      } else {
        setStep('profile');
      }
  
      // Fetch user feedback
      const feedbackResponse = await axios.get('https://api.youelite.net/api/user-feedback', {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      setUserFeedback(feedbackResponse.data);
  
    } catch (error) {
      console.error('Error checking user profile:', error);
      setStep('profile');
    }
  }, []);
  


  useEffect(() => {
  }, [userFeedback]);
  
  useEffect(() => {
    checkUserProfile();
  }, [checkUserProfile]);

  useEffect(() => {
    if (step === 'interestAssessment') {
      fetchInterestQuestions(currentPage);
    }
  }, [step, currentPage]);

  const fetchInterestQuestions = async (page) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    const questionsPerPage = 12;
    const start = (page - 1) * questionsPerPage + 1;
    const end = page * questionsPerPage;

    try {
      const response = await axios.get(`https://api.youelite.net/api/interest-profiler?start=${start}&end=${end}`, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });

      const { question, total } = response.data;

      setInterestQuestions(question);
      setTotalPages(Math.ceil(total / questionsPerPage));
    } catch (error) {
      console.error('Error fetching interest questions:', error);
    }
  };


  const handleAnswerChange = (questionId, value) => {
    setInterestAnswers(prevAnswers => ({ ...prevAnswers, [questionId]: value }));
  };

  const submitProfile = () => {
    setStep('interestAssessment');
  };

  const submitInterestAssessment = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    try {
      const response = await axios.post('https://api.youelite.net/api/interest-profiler/results', 
        { 
          answers: Object.values(interestAnswers).join(''),
          profile: profile
        }, 
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      
      setResults({ result: response.data.result });
      await fetchRecommendations(response.data);
    } catch (error) {
      console.error('Error submitting interest assessment:', error);
    }
  };

  const fetchRecommendations = async (results) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    try {
      const response = await axios.post('https://api.youelite.net/api/matching-careers', { answers: results.answers }, {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      });
      setRecommendations(response.data);
      setStep('results');
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    }
  };

  const submitFeedback = async (major, liked) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    try {
      await axios.post('https://api.youelite.net/api/submit-feedback', 
        { major, liked },
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      setUserFeedback(prevFeedback => ({
        ...prevFeedback,
        [major]: liked
      }));
      console.log(`Feedback for major ${major}: ${liked ? 'Liked' : 'Disliked'}`);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const getRefinedRecommendations = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    try {
      const response = await axios.post('https://api.youelite.net/api/refined-recommendations',
        { originalRecommendations: recommendations },
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
      );
      setRecommendations(response.data);
      setIsRefined(true);
    } catch (error) {
      console.error('Error getting refined recommendations:', error);
    }
  };

  const renderProfile = () => (
    <Card className="card-one">
      <Card.Header>
        <Card.Title as="h6">How It Works</Card.Title>
      </Card.Header>
      <Card.Body>
        <div>
          <h5>Welcome to the Major Matchmaker!</h5>
          <p>
            Our system is designed to help you find the best majors and career paths based on your interests and preferences. Here's how it works:
          </p>
          <ol>
            <li><strong>Profile Integration:</strong> You will set up a basic profile to help us understand your background and aspirations.</li>
            <li><strong>Interest Assessment:</strong> You will complete an interest assessment, answering a series of questions that gauge your preferences across various activities and subjects.</li>
            <li><strong>Results and Recommendations:</strong> Based on your responses, we will generate a profile that highlights your dominant interest areas. You will receive recommendations for majors and careers that align with your interests.</li>
            <li><strong>Feedback:</strong> You can provide feedback on the recommended majors, letting us know which ones you like or dislike. This feedback helps refine your recommendations further.</li>
            <li><strong>Refined Recommendations:</strong> After providing feedback, you can receive refined recommendations that are more tailored to your preferences.</li>
          </ol>
          <p>
            Click the button below to get started with the interest assessment and discover your potential career paths!
          </p>
          <Button variant="primary" onClick={submitProfile}>Continue to Assessment</Button>
          <p style={{ marginTop: '20px' }}>
            This site incorporates information from <a href="https://services.onetcenter.org/" target="_blank" rel="noopener noreferrer">O*NET Web Services</a> by the U.S. Department of Labor, Employment and Training Administration (USDOL/ETA).
          </p>
        </div>
      </Card.Body>
    </Card>
  );  
  
  

  const renderAssessment = () => {
    return (
      <Card className="card-one">
        <Card.Header>
          <Card.Title as="h6">Interest Profiler</Card.Title>
        </Card.Header>
        <Card.Body>
          <p><strong>Do you like to / Would you like to</strong></p>
          {interestQuestions && interestQuestions.length > 0 ? interestQuestions.map((question) => (
            <Form.Group key={`page-${currentPage}-question-${question.index}`} className="mb-3">
              <Form.Label>{question.text}</Form.Label>
              <Form.Control 
                as="select" 
                onChange={(e) => handleAnswerChange(question.index, e.target.value)}
                value={interestAnswers[question.index] || ''}
              >
                <option value="">Select an option</option>
                <option value="1">Strongly Dislike</option>
                <option value="2">Dislike</option>
                <option value="3">Unsure</option>
                <option value="4">Like</option>
                <option value="5">Strongly Like</option>
              </Form.Control>
            </Form.Group>
          )) : <p>Loading questions...</p>}
          <Button variant="primary" onClick={currentPage < totalPages ? nextPage : submitInterestAssessment}>
            {currentPage < totalPages ? "Next Page" : "Submit Assessment"}
          </Button>
        </Card.Body>
      </Card>
    );
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderResults = () => {
    // Sort the results array by score in descending order
    const sortedResults = [...results.result].sort((a, b) => b.score - a.score);
  
    const radarChartData = {
      labels: sortedResults.map(result => result.area),
      datasets: [{
        label: 'Interest Profile',
        data: sortedResults.map(result => result.score),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgb(75, 192, 192)',
        pointBackgroundColor: 'rgb(75, 192, 192)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(75, 192, 192)'
      }]
    };
  
    const radarChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        r: {
          angleLines: {
            display: false
          },
          suggestedMin: 0,
          suggestedMax: 30
        }
      }
    };
  
    // Group recommendations by major
    const groupedRecommendations = recommendations.reduce((acc, rec) => {
      if (!acc[rec.major]) {
        acc[rec.major] = [];
      }
      acc[rec.major].push(rec);
      return acc;
    }, {});
  
    return (
      <>
        <Card className="card-one">
          <Card.Header>
            <Card.Title as="h6">Interest Profiler Results</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="d-flex">
              <div style={{ width: '66%', paddingRight: '20px' }}>
                {sortedResults.map((result, index) => (
                  <div key={index}>
                    <h5>{result.area}</h5>
                    <p>Score: {result.score}</p>
                    <p>{result.description}</p>
                  </div>
                ))}
              </div>
              <div style={{ width: '34%', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Radar data={radarChartData} options={radarChartOptions} />
                <Button variant="danger" className="mt-3" onClick={handleSurveyAgain}>
                  Repeat Survey
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="card-one mt-4">
          <Card.Header>
            <Card.Title as="h6">
              {isRefined ? "Refined Major Recommendations" : "Major Recommendations"}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {!isRefined && (
              <Button 
                variant="primary" 
                onClick={getRefinedRecommendations} 
                className="mb-3"
                disabled 
                style={{ display: 'none' }}
              >
                Refine Recommendations
              </Button>
            )}
            {Object.entries(groupedRecommendations).map(([major, jobs], majorIndex) => ( 
              <React.Fragment key={majorIndex}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h5>{major}</h5>
                  <div>
                    <Button 
                      variant={likedMajors.includes(major) ? "success" : "outline-success"} 
                      onClick={() => {
                        submitFeedback(major, 1); 
                        saveMajorPreference(major, 'like');
                      }} 
                      className="me-2"
                    >
                      <i className="ri-thumb-up-line"></i>
                    </Button>
                    <Button 
                      variant={dislikedMajors.includes(major) ? "danger" : "outline-danger"} 
                      onClick={() => {
                        submitFeedback(major, 0); 
                        saveMajorPreference(major, 'dislike');
                      }}
                    >
                      <i className="ri-thumb-down-line"></i>
                    </Button>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Job Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobs.map((job, jobIndex) => (
                      <tr key={`${majorIndex}-${jobIndex}`}>
                        <td>{job.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {majorIndex < Object.entries(groupedRecommendations).length - 1 && <hr />}
              </React.Fragment>
            ))}
          </Card.Body>
        </Card>
      </>
    );
  };
  
  

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb4">
          <div>
            <h4 className="main-title mb-2">Major Matchmaker</h4>
          </div>
        </div>

        <Toast 
          onClose={() => setShowToast(false)} 
          show={showToast} 
          delay={3000} 
          autohide 
          style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 9999 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>




        {step === 'loading' && <p>Loading...</p>}
        {step === 'profile' && renderProfile()}
        {step === 'interestAssessment' && renderAssessment()}
        {step === 'results' && renderResults()}
        <Footer className="footer"/>
      </div>
    </React.Fragment>
  );
}
