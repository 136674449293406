// src/pages/AdminQuestionsPage.js

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  ListGroup,
  Modal,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import axios from "axios";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaClock,
  FaEdit,
} from "react-icons/fa";

const StyledCard = styled(Card)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 12px;
  background-color: #ffffff;
`;

const StyledListGroup = styled(ListGroup)`
  margin: 0;
  padding: 0;
  max-height: 600px;
  overflow-y: auto;
`;

const StyledListItem = styled(ListGroup.Item)`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: background-color 0.2s;

  &:nth-child(odd) {
    background-color: #f8f9fa;
  }

  &:hover {
    background-color: #e9ecef;
  }
`;

const StatusIcon = styled.span`
  margin-right: 10px;
  color: ${(props) => {
    if (props.status === "approved") return "#4CAF50";
    if (props.status === "awaiting_approval") return "#FFA500";
    return "#DC3545";
  }};
`;

const AdminQuestionsPage = () => {
  // State variables for user-submitted questions
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // For approve/reject modal
  const [showEditModal, setShowEditModal] = useState(false); // For edit modal
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [error, setError] = useState(null);

  // State variables for guest questions
  const [guestQuestions, setGuestQuestions] = useState([]);
  const [selectedGuestQuestion, setSelectedGuestQuestion] = useState(null);
  const [editedGuestQuestion, setEditedGuestQuestion] = useState("");
  const [guestAnswerText, setGuestAnswerText] = useState("");
  const [guestLoading, setGuestLoading] = useState(true);
  const [showGuestModal, setShowGuestModal] = useState(false); // For guest approve/reject modal
  const [showGuestEditModal, setShowGuestEditModal] = useState(false); // For guest edit modal
  const [submittingGuestAnswer, setSubmittingGuestAnswer] = useState(false);
  const [guestError, setGuestError] = useState(null);

  useEffect(() => {
    document.title = "Admin Questions | YouElite";
    fetchQuestions();
    fetchGuestQuestions();
  }, []);

  // Fetch all submitted questions
  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await axios.get(
        "https://api.youelite.net/api/admin/questions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.data.success) {
        setQuestions(response.data.questions);
      } else {
        setError("Failed to fetch questions");
      }
    } catch (error) {
      console.error("Failed to fetch questions", error);
      setError("Failed to fetch questions");
    } finally {
      setLoading(false);
    }
  };

  // Fetch all submitted guest questions
  const fetchGuestQuestions = async () => {
    try {
      setGuestLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await axios.get(
        "https://api.youelite.net/api/admin/guest-questions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.data.success) {
        setGuestQuestions(response.data.guest_questions);
      } else {
        setGuestError("Failed to fetch guest questions");
      }
    } catch (error) {
      console.error("Failed to fetch guest questions", error);
      setGuestError("Failed to fetch guest questions");
    } finally {
      setGuestLoading(false);
    }
  };

  // Handle approving an answer
  const handleApproveAnswer = async (questionId) => {
    try {
      setSubmittingAnswer(true);
      const user = JSON.parse(localStorage.getItem("user"));
      await axios.post(
        `https://api.youelite.net/api/admin/questions/${questionId}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchQuestions(); // Refresh the list after approval
    } catch (error) {
      console.error("Failed to approve answer", error);
      alert("Failed to approve answer");
    } finally {
      setSubmittingAnswer(false);
      setShowModal(false);
    }
  };

  // Handle rejecting an answer
  const handleRejectAnswer = async (questionId) => {
    try {
      setSubmittingAnswer(true);
      const user = JSON.parse(localStorage.getItem("user"));
      await axios.post(
        `https://api.youelite.net/api/admin/questions/${questionId}/resend`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchQuestions(); // Refresh the list after rejection
    } catch (error) {
      console.error("Failed to reject answer", error);
      alert("Failed to reject answer");
    } finally {
      setSubmittingAnswer(false);
      setShowModal(false);
    }
  };

  // Handle editing the question and answer
  const handleEditSubmit = async () => {
    setSubmittingAnswer(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      await axios.post(
        `https://api.youelite.net/api/admin/questions/${selectedQuestion.id}/edit`,
        { question: editedQuestion, answer: answerText },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchQuestions(); // Refresh questions list after editing
      setEditedQuestion(""); // Clear question text
      setAnswerText(""); // Clear answer text
    } catch (error) {
      console.error("Failed to submit edit", error);
      alert("Failed to submit edit");
    } finally {
      setSubmittingAnswer(false);
      setShowEditModal(false);
    }
  };

  // Open modal with selected question
  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setShowModal(true); // Open the modal for approval/rejection
  };

  // Open edit modal with selected question
  const handleEditClick = (question) => {
    setSelectedQuestion(question);
    setEditedQuestion(question.question || ""); // Pre-fill question for editing
    setAnswerText(question.answer || ""); // Pre-fill answer for editing
    setShowEditModal(true); // Open the edit modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedQuestion(null);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedQuestion(null);
    setEditedQuestion("");
    setAnswerText("");
  };

  // Handle approving a guest answer
  const handleApproveGuestAnswer = async (questionId) => {
    try {
      setSubmittingGuestAnswer(true);
      const user = JSON.parse(localStorage.getItem("user"));
      await axios.post(
        `https://api.youelite.net/api/admin/guest-questions/${questionId}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchGuestQuestions(); // Refresh the list after approval
    } catch (error) {
      console.error("Failed to approve guest answer", error);
      alert("Failed to approve guest answer");
    } finally {
      setSubmittingGuestAnswer(false);
      setShowGuestModal(false);
    }
  };

  // Handle rejecting a guest answer
  const handleRejectGuestAnswer = async (questionId) => {
    try {
      setSubmittingGuestAnswer(true);
      const user = JSON.parse(localStorage.getItem("user"));
      await axios.post(
        `https://api.youelite.net/api/admin/guest-questions/${questionId}/resend`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchGuestQuestions(); // Refresh the list after rejection
    } catch (error) {
      console.error("Failed to reject guest answer", error);
      alert("Failed to reject guest answer");
    } finally {
      setSubmittingGuestAnswer(false);
      setShowGuestModal(false);
    }
  };

  // Handle editing the guest question and answer
  const handleEditGuestSubmit = async () => {
    setSubmittingGuestAnswer(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      await axios.post(
        `https://api.youelite.net/api/admin/guest-questions/${selectedGuestQuestion.id}/edit`,
        { answer: guestAnswerText },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      fetchGuestQuestions(); // Refresh guest questions list after editing
      setEditedGuestQuestion(""); // Clear question text
      setGuestAnswerText(""); // Clear answer text
    } catch (error) {
      console.error("Failed to submit guest edit", error);
      alert("Failed to submit guest edit");
    } finally {
      setSubmittingGuestAnswer(false);
      setShowGuestEditModal(false);
    }
  };

  // Open modal with selected guest question
  const handleGuestQuestionClick = (question) => {
    setSelectedGuestQuestion(question);
    setShowGuestModal(true); // Open the modal for approval/rejection
  };

  // Open edit modal with selected guest question
  const handleGuestEditClick = (question) => {
    setSelectedGuestQuestion(question);
    setGuestAnswerText(question.answer || ""); // Pre-fill answer for editing
    setShowGuestEditModal(true); // Open the edit modal
  };

  const handleCloseGuestModal = () => {
    setShowGuestModal(false);
    setSelectedGuestQuestion(null);
  };

  const handleCloseGuestEditModal = () => {
    setShowGuestEditModal(false);
    setSelectedGuestQuestion(null);
    setGuestAnswerText("");
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <h4 className="main-title mb-4">Manage Submitted Questions</h4>
        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        <Row className="g-4">
          <Col>
            {/* Submitted Questions */}
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <StyledCard>
                <Card.Header>
                  <Card.Title as="h6">Submitted Questions</Card.Title>
                </Card.Header>
                <Card.Body>
                  {questions.length > 0 ? (
                    <StyledListGroup>
                      {questions.map((question) => (
                        <StyledListItem key={question.id}>
                          <div
                            onClick={() => handleQuestionClick(question)}
                            style={{ flex: 1, cursor: "pointer" }}
                          >
                            <StatusIcon status={question.status}>
                              {question.status === "approved" ? (
                                <FaCheckCircle />
                              ) : question.status === "awaiting_approval" ? (
                                <FaClock />
                              ) : (
                                <FaTimesCircle />
                              )}
                            </StatusIcon>
                            {question.question}
                          </div>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleEditClick(question)}
                          >
                            <FaEdit />
                          </Button>
                        </StyledListItem>
                      ))}
                    </StyledListGroup>
                  ) : (
                    <p className="text-muted">No questions available.</p>
                  )}
                </Card.Body>
              </StyledCard>
            )}

            {/* Guest Questions */}
            {guestLoading ? (
              <div className="text-center mt-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <StyledCard className="mt-4">
                <Card.Header>
                  <Card.Title as="h6">Guest Questions</Card.Title>
                </Card.Header>
                <Card.Body>
                  {guestQuestions.length > 0 ? (
                    <StyledListGroup>
                      {guestQuestions.map((question) => (
                        <StyledListItem key={question.id}>
                          <div
                            onClick={() => handleGuestQuestionClick(question)}
                            style={{ flex: 1, cursor: "pointer" }}
                          >
                            <StatusIcon status={question.status}>
                              {question.status === "approved" ? (
                                <FaCheckCircle />
                              ) : question.status === "awaiting_approval" ? (
                                <FaClock />
                              ) : (
                                <FaTimesCircle />
                              )}
                            </StatusIcon>
                            {question.question}
                          </div>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleGuestEditClick(question)}
                          >
                            <FaEdit />
                          </Button>
                        </StyledListItem>
                      ))}
                    </StyledListGroup>
                  ) : (
                    <p className="text-muted">No guest questions available.</p>
                  )}
                </Card.Body>
              </StyledCard>
            )}
          </Col>
        </Row>

        {/* Modal for editing question and answer */}
        <Modal show={showEditModal} onHide={handleCloseEditModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Question and Answer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Question:</h5>
            <Form.Control
              as="textarea"
              rows={3}
              value={editedQuestion}
              onChange={(e) => setEditedQuestion(e.target.value)} // Handle question editing
              placeholder="Edit the question here..."
            />
            <h5>Answer:</h5>
            <Form.Control
              as="textarea"
              rows={5}
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)} // Handle answer editing
              placeholder="Edit the answer here..."
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleEditSubmit}
              disabled={submittingAnswer || !editedQuestion || !answerText}
            >
              {submittingAnswer ? "Saving..." : "Save Changes"}
            </Button>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for approving/rejecting answer */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Question Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Question:</h5>
            <ReactMarkdown>
              {selectedQuestion?.question || "No question text available."}
            </ReactMarkdown>
            <h5>Answer:</h5>
            <ReactMarkdown>
              {selectedQuestion?.answer || "No answer available."}
            </ReactMarkdown>
          </Modal.Body>
          <Modal.Footer>
            {selectedQuestion &&
              selectedQuestion.answer &&
              selectedQuestion.status === "awaiting_approval" && (
                <>
                  <Button
                    variant="danger"
                    onClick={() => handleRejectAnswer(selectedQuestion.id)}
                    disabled={submittingAnswer}
                  >
                    {submittingAnswer ? "Rejecting..." : "Reject Answer"}
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => handleApproveAnswer(selectedQuestion.id)}
                    disabled={submittingAnswer}
                  >
                    {submittingAnswer ? "Approving..." : "Approve Answer"}
                  </Button>
                </>
              )}
            {selectedQuestion?.status === "approved" && (
              <Button variant="secondary" disabled>
                Answer Approved
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for editing guest answer */}
        <Modal
          show={showGuestEditModal}
          onHide={handleCloseGuestEditModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Guest Answer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Question:</h5>
            <ReactMarkdown>
              {selectedGuestQuestion?.question ||
                "No question text available."}
            </ReactMarkdown>
            <h5>Answer:</h5>
            <Form.Control
              as="textarea"
              rows={5}
              value={guestAnswerText}
              onChange={(e) => setGuestAnswerText(e.target.value)} // Handle answer editing
              placeholder="Edit the answer here..."
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleEditGuestSubmit}
              disabled={submittingGuestAnswer || !guestAnswerText}
            >
              {submittingGuestAnswer ? "Saving..." : "Save Changes"}
            </Button>
            <Button variant="secondary" onClick={handleCloseGuestEditModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for approving/rejecting guest answer */}
        <Modal
          show={showGuestModal}
          onHide={handleCloseGuestModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Guest Question Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Question:</h5>
            <ReactMarkdown>
              {selectedGuestQuestion?.question ||
                "No question text available."}
            </ReactMarkdown>
            <h5>Answer:</h5>
            <ReactMarkdown>
              {selectedGuestQuestion?.answer || "No answer available."}
            </ReactMarkdown>
          </Modal.Body>
          <Modal.Footer>
            {selectedGuestQuestion &&
              selectedGuestQuestion.answer &&
              selectedGuestQuestion.status === "awaiting_approval" && (
                <>
                  <Button
                    variant="danger"
                    onClick={() =>
                      handleRejectGuestAnswer(selectedGuestQuestion.id)
                    }
                    disabled={submittingGuestAnswer}
                  >
                    {submittingGuestAnswer ? "Rejecting..." : "Reject Answer"}
                  </Button>
                  <Button
                    variant="success"
                    onClick={() =>
                      handleApproveGuestAnswer(selectedGuestQuestion.id)
                    }
                    disabled={submittingGuestAnswer}
                  >
                    {submittingGuestAnswer ? "Approving..." : "Approve Answer"}
                  </Button>
                </>
              )}
            {selectedGuestQuestion?.status === "approved" && (
              <Button variant="secondary" disabled>
                Answer Approved
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseGuestModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AdminQuestionsPage;
