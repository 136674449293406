import React, { useEffect, useState, useMemo, useCallback } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {Modal, Button, Card, Col, Row, Table, Spinner } from "react-bootstrap";
import { Doughnut } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import axios from 'axios';
import '../assets/css/ApplicationTracker.css'; 
import { FaGem, FaTimes } from "react-icons/fa";


import NewApplicationOverlay from '../components/NewApplicationOverlay';
import EditApplicationOverlay from '../components/EditApplicationOverlay';
import FileUploadOverlay from '../components/FileUploadOverlay';

axios.defaults.baseURL = 'https://api.youelite.net/api';

axios.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default function EventManagement() {
  
  useEffect(() => {
    document.title = "Application Tracker | YouElite";
  }, []);

  
  const [applications, setApplications] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [deadlines, setDeadlines] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || 'light');
  const [showNewApplicationOverlay, setShowNewApplicationOverlay] = useState(false);
  const [showEditApplicationOverlay, setShowEditApplicationOverlay] = useState(false);
  const [showFileUploadOverlay, setShowFileUploadOverlay] = useState(false);
  const [editingApplication, setEditingApplication] = useState(null); 
  const [uploadingApplication, setUploadingApplication] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [averageTimeToComplete, setAverageTimeToComplete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);




  const fetchApplicationsAndDocuments = useCallback(async () => {
    setIsLoading(true); // Start loading
    try {
      const applicationsResponse = await axios.get('/applications');
      const { applications, average_days_to_complete } = applicationsResponse.data;
  
      setApplications(applications);
      setAverageTimeToComplete(average_days_to_complete);
  
      // Extract documents from the applications response
      const allDocuments = applications.flatMap(application => 
        application.documents.map(doc => ({ ...doc, applicationId: application.id }))
      );
  
      setDocuments(allDocuments);
  
    } catch (error) {
      console.error('Error fetching applications:', error);
      if (error.response && error.response.status === 403) {
        if (error.response.data.error_code === 'PLAN_LIMIT_REACHED') {
          closeAllPopups();
          setShowUpgradeModal(true);
        }
      } else {
        console.log('An unexpected error occurred:', error);
      }
    } finally {
      setIsLoading(false); // End loading
    }
  }, []);





  useEffect(() => {
    console.log('useEffect triggered');
    switchSkin(skin);
    fetchApplicationsAndDocuments(); 
    fetchCollegeDeadlines(); 
  }, [skin]);
  



  
  


  const addApplication = (newApplication) => {
    setApplications(prevApplications => [...prevApplications, newApplication]);
    if (newApplication.documents) {
      setDocuments(prevDocuments => [
        ...prevDocuments, 
        ...newApplication.documents.map(doc => ({ ...doc, applicationId: newApplication.id }))
      ]);
    }
  };


  const handlePlanLimitReached = () => {
    closeAllPopups();  // Ensure all popups are closed
    setShowUpgradeModal(true);  // Show the upgrade modal
  };


  const updateApplication = (updatedApplication) => {
    setApplications(prevApplications => 
      prevApplications.map(app => app.id === updatedApplication.id ? updatedApplication : app)
    );
    if (updatedApplication.documents) {
      setDocuments(prevDocuments => {
        const filteredDocuments = prevDocuments.filter(doc => doc.applicationId !== updatedApplication.id);
        return [
          ...filteredDocuments,
          ...updatedApplication.documents.map(doc => ({ ...doc, applicationId: updatedApplication.id }))
        ];
      });
    }
  };

  const downloadDocument = (url, fileName) => {
    const completeUrl = `https://api.youelite.net/storage/app/documents/${url}`; // Base URL prepended to the document URL
    
    axios({
      url: completeUrl,
      method: 'GET',
      responseType: 'blob', // This ensures we get the file as a binary large object (blob)
    })
      .then((response) => {
        // Create a link element for download
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName || 'document.pdf'); // Set file name for download
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('Error downloading the document:', error);
      });
  };
  
  

  const fetchCollegeDeadlines = useCallback(async () => {
    try {
        const response = await axios.get('/user/colleges/deadlines');
        setDeadlines(response.data);
        setCalendarEvents(response.data.map(deadline => ({
            title: deadline.title,
            start: new Date(deadline.start),
            end: new Date(deadline.start)
        })));
    } catch (error) {
        console.error('Error fetching college deadlines:', error);
    }
  }, []);


  const deleteApplication = async (applicationId) => {
    try {
      await axios.delete(`/applications/${applicationId}`);
      setApplications(prevApplications => prevApplications.filter(app => app.id !== applicationId));
      setDocuments(prevDocuments => prevDocuments.filter(doc => doc.applicationId !== applicationId));
    } catch (error) {
      console.error('Error deleting application:', error);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      await axios.delete(`/documents/${documentId}`);
      setDocuments(prevDocuments => prevDocuments.filter(doc => doc.id !== documentId));
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const switchSkin = useCallback((skin) => {
    const textPrimary = document.getElementsByClassName('text-primary-dark');

    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.add('text-primary');
      }

      document.body.classList.add('dark-mode');
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }

      for (const txt of textPrimary) {
        txt.classList.remove('text-primary');
      }

      document.body.classList.remove('dark-mode');
    }

    localStorage.setItem('skin-mode', skin);
  }, []);

  const applicationStatusData = useMemo(() => {
    const statusCounts = applications.reduce((acc, app) => {
        acc[app.status] = (acc[app.status] || 0) + 1;
        return acc;
    }, {});



    const majorCounts = applications.reduce((acc, app) => {
        acc[app.major] = (acc[app.major] || 0) + 1;
        return acc;
    }, {});

    const predominantMajor = Object.keys(majorCounts).reduce((a, b) => majorCounts[a] > majorCounts[b] ? a : b, '');

    return {
        labels: ['Not Started', 'In Progress', 'Submitted', 'Accepted', 'Rejected'],
        datasets: [
            {
                data: [
                    statusCounts['not started'] || 0,
                    statusCounts['in progress'] || 0,
                    statusCounts['submitted'] || 0,
                    statusCounts['accepted'] || 0,
                    statusCounts['rejected'] || 0,
                ],
                backgroundColor: ['#6c5ce7', '#17a2b8', '#007bff', '#28a745', '#dc3545'],
                hoverBackgroundColor: ['#a29bfe', '#74b9ff', '#4287f5', '#55efc4', '#fab1a0'],
            },
        ],
        averageTimeToComplete: averageTimeToComplete,
        predominantMajor: predominantMajor
    };
  }, [applications]);


  const handleEditClick = (application) => {
    setEditingApplication(application);
    setShowEditApplicationOverlay(true);
  };

  const handleUploadClick = (application) => {
    setUploadingApplication(application);
    setShowFileUploadOverlay(true);
  };

  const handleFileUpload = async (fileName, files) => {
    try {
      const formData = new FormData();
      formData.append('document_name', fileName);
      formData.append('file', files[0]);
  
      const response = await axios.post(`/applications/${uploadingApplication.id}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const newDocument = { ...response.data, applicationId: uploadingApplication.id };
      setDocuments(prevDocuments => [...prevDocuments, newDocument]);
  
      setShowFileUploadOverlay(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      if (error.response && error.response.status === 403 && error.response.data.error_code === 'PLAN_LIMIT_REACHED') {
        handlePlanLimitReached();
      }
    }
  };

  const getStatusBadgeStyle = (status) => {
    switch (status) {
      case 'accepted':
        return { backgroundColor: '#28a745', color: '#fff' }; 
      case 'pending':
        return { backgroundColor: '#ffc107', color: '#000' }; 
      case 'rejected':
        return { backgroundColor: '#dc3545', color: '#fff' }; 
      case 'in progress':
        return { backgroundColor: '#17a2b8', color: '#fff' }; 
      case 'submitted':
        return { backgroundColor: '#007bff', color: '#fff' }; 
      default:
        return { backgroundColor: '#6c757d', color: '#fff' }; 
    }
  };
  


  const closeAllPopups = () => {
    setShowNewApplicationOverlay(false);
    setShowEditApplicationOverlay(false);
    setShowFileUploadOverlay(false);
    setUploadingApplication(null);  // Ensure the upload overlay closes if open
    setEditingApplication(null);  // Ensure the edit overlay closes if open
  };
  
  


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Application Tracker</li>
            </ol>
            <h4 className="main-title mb-0">Application Tracker</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="8">
            <Card className="card-college-list d-flex flex-column">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title as="h6">My Applications</Card.Title>
                <div className="d-flex align-items-center">
                  <Button variant="primary" size="sm" className="me-2" onClick={() => setShowNewApplicationOverlay(true)}>New Application</Button>
                    <NewApplicationOverlay
                      show={showNewApplicationOverlay}
                      handleClose={() => setShowNewApplicationOverlay(false)}
                      addApplication={addApplication}
                      onPlanLimitReached={handlePlanLimitReached}
                    />
                </div>
              </Card.Header>
              <Card.Body className="card-body">
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : applications.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <p>Start adding your applications!</p>
                  </div>
                ) : (
                  <Table responsive className="table-application mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: '25%' }}>College</th>
                        <th style={{ width: '20%' }}>Major</th>
                        <th style={{ width: '10%' }}>Round</th>
                        <th style={{ width: '15%' }}>Deadline</th>
                        <th style={{ width: '10%' }}>Fee</th>
                        <th style={{ width: '10%' }}>Status</th>
                        <th style={{ width: '10%' }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applications.map(application => (
                        <tr key={application.id}>
                          <td style={{ wordWrap: 'break-word', maxWidth: '25%' }}>{application.college?.INSTNM || 'Unknown College'}</td>
                          <td style={{ wordWrap: 'break-word', maxWidth: '20%' }}>{application.major}</td>
                          <td>{application.round}</td>
                          <td>{application.submission_date}</td>
                          <td>${application.application_fee}</td>
                          <td>
                            <span className="badge" style={getStatusBadgeStyle(application.status)}>
                              {application.status}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Button variant="white" size="sm" className="btn-icon" onClick={() => handleEditClick(application)}>
                                <i className="ri-edit-2-line"></i>
                              </Button>
                              <Button variant="white" size="sm" className="btn-icon" onClick={() => deleteApplication(application.id)}>
                                <i className="ri-delete-bin-line"></i>
                              </Button>
                              <Button variant="white" size="sm" className="btn-icon" onClick={() => handleUploadClick(application)}>
                                <i className="ri-attachment-line"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>

            </Card>
          </Col>
          <Col xl="4">
            <Row className="g-3">
              <Col xs="12">
                <Card className="card-college-list">
                  <Card.Header>
                    <Card.Title as="h6">Summary</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {isLoading ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : applications.length === 0 ? (
                      <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p>Start adding your applications!</p>
                      </div>
                    ) : (
                      <div>
                        <div style={{ height: '200px' }}>
                          <Doughnut 
                            data={applicationStatusData} 
                            options={{
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  position: 'bottom',
                                }
                              }
                            }}
                          />
                        </div>
                        <div style={{ marginTop: '10px', textAlign: 'center', fontSize: '14px' }}>
                          <p style={{ margin: '5px 0' }}>
                            <strong>Average Time to Complete:</strong> {averageTimeToComplete ? `${averageTimeToComplete.toFixed(2)} days` : 'N/A'} days
                          </p>
                          <p style={{ margin: '5px 0' }}>
                            <strong>Predominant Major:</strong> {applicationStatusData.predominantMajor || 'N/A'}
                          </p>
                        </div>
                      </div>
                    )}
                  </Card.Body>



                </Card>
              </Col>
              <Col xs="12">
                <Card className="card-college-list">
                  <Card.Header>
                    <Card.Title as="h6">My Documents</Card.Title>
                  </Card.Header>
                  <Card.Body className="card-body">
                    <Table responsive="sm" className="table-application mb-0">
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>College</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents && documents.map((document) => (
                          <tr key={document.id}>
                            <td>{document.document_name}</td> {/* Just a plain text now */}
                            <td>{applications.find((app) => app.id === document.applicationId)?.college?.INSTNM || 'Unknown College'}</td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <Button 
                                  variant="white" 
                                  size="sm" 
                                  className="btn-icon" 
                                  onClick={() => downloadDocument(document.document_url, document.document_name)}>
                                  <i className="ri-download-2-line"></i>
                                </Button>
                                <Button 
                                  variant="white" 
                                  size="sm" 
                                  className="btn-icon" 
                                  onClick={() => deleteDocument(document.id)}>
                                  <i className="ri-delete-bin-line"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="g-3 mt-4" style={{ display: "none" }}>
          <Col xs="12">
            <Card className="card-tr">
              <Card.Header>
                <Card.Title as="h6">Deadlines</Card.Title>
              </Card.Header>
              <Card.Body className="card-body">
                <div className="events-list">
                  {deadlines && deadlines.length > 0 ? (
                    deadlines.map((deadline, index) => (
                      <div key={index} className="d-flex align-items-start mb-3">
                        <div className="event-date text-center me-3">
                          <small>{new Date(deadline.start).toLocaleDateString('en-US', { month: 'short' }).toUpperCase()}</small>
                          <h5>{new Date(deadline.start).toLocaleDateString('en-US', { day: 'numeric' })}</h5>
                        </div>
                        <div className="events-body flex-grow-1">
                          <h6 className="mb-1">{deadline.title || 'No Title'}</h6>
                          {deadline.extendedProps?.rounds && deadline.extendedProps.rounds.length > 0 ? (
                            deadline.extendedProps.rounds.map((round, i) => (
                              <div key={i} className="ev-item">
                                <small>{round.name}</small>
                                <h6>{new Date(round.date).toLocaleDateString('en-US')}</h6>
                              </div>
                            ))
                          ) : (
                            <small>No rounds available</small>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No deadlines available</div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Edit Application Overlay */}
        {editingApplication && (
          <EditApplicationOverlay
            show={showEditApplicationOverlay}
            handleClose={() => setShowEditApplicationOverlay(false)}
            application={editingApplication}
            onApplicationUpdated={updateApplication}
          />
        )}

    <Modal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} centered>
        <Modal.Body className="text-center p-4" style={{ position: 'relative' }}>
            {/* Close Button in the body */}
            <FaTimes 
                size={24} 
                color="#6c757d" 
                style={{ 
                    position: 'absolute', 
                    top: '10px', 
                    right: '10px', 
                    cursor: 'pointer' 
                }} 
                onClick={() => setShowUpgradeModal(false)}
            />

            <FaGem size={48} color="#6c757d" />
            <h4 className="my-4">Don't Let Your College Apps Play Hide and Seek!</h4>
            <p>Your college applications shouldn't be harder to track than your missing socks.</p>
            <p>
            For just <del>$39.99</del> <span style={{ color: '#d4af37', fontWeight: 'bold' }}>$14.99</span>, upgrade and get the ultimate application tracker—because staying organized should be the easiest part of getting into your dream school. No more guessing games!
            </p>
            <Button variant="success" size="lg" onClick={() => { setShowUpgradeModal(false); window.location.href = "/plans"; }}>
            Upgrade and Track Your Success!
            </Button>
        </Modal.Body>
    </Modal>


        {/* File Upload Overlay */}
        {uploadingApplication && (
          <FileUploadOverlay
              show={showFileUploadOverlay}
              handleClose={() => setShowFileUploadOverlay(false)}
              onUpload={handleFileUpload}
              application={uploadingApplication}
              onPlanLimitReached={handlePlanLimitReached}  // Pass the plan limit handler
          />
        )}
        <Footer className="footer"/>
      </div>
    </React.Fragment>
  );
}