import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const ProfileSetup = () => {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zip_code: ''
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = localStorage.getItem('user');
      if (!user) {
        setMessage('No authentication token found');
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(user);

      try {
        const response = await axios.get('https://api.youelite.net/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        const data = response.data;
        setFormData({
          phoneNumber: data.phoneNumber || '',
          address: data.address || '',
          city: data.city || '',
          state: data.state || '',
          zip_code: data.zip_code || ''
        });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setMessage('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const user = localStorage.getItem('user');
    if (!user) {
      setMessage('No authentication token found');
      return;
    }
  
    const { token } = JSON.parse(user);
  
    try {
      await axios.put('https://api.youelite.net/api/user', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('Profile updated successfully');
      setTimeout(() => {
        navigate('/academic-interests');
      }, 2000);
    } catch (error) {
      console.error('Failed to update profile:', error);
      setMessage('Failed to update profile');
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">YouElite</Link>
          <Card.Title>Profile Setup</Card.Title>
          <Card.Text>Complete your profile information.</Card.Text>
        </Card.Header>
        <Card.Body>
          {message && <div className="alert alert-info">{message}</div>}
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your phone number" 
                name="phoneNumber"
                value={formData.phoneNumber} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your address" 
                name="address"
                value={formData.address} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your city" 
                name="city"
                value={formData.city} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your state" 
                name="state"
                value={formData.state} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your zip code" 
                name="zip_code"
                value={formData.zip_code} 
                onChange={handleChange} 
                required 
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Save and Continue</Button>
          </Form>
          <Button variant="link" onClick={() => navigate('/academic-interests')} style={{color: '#007bff', textDecoration: 'underline', marginTop: '10px'}}>Skip</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProfileSetup;
