import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate

const ManageSubscriptionButton = () => {
    const navigate = useNavigate(); // Replaced useHistory with useNavigate

    const handleManageSubscription = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user?.token;

            if (!token) {
                alert('You need to be logged in to manage your subscription.');
                return;
            }

            const response = await fetch('https://api.youelite.net/api/billing-portal-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const session = await response.json();

            if (session.url) {
                window.location.href = session.url;
            }
        } catch (error) {
            console.error('Error creating billing portal session:', error);
            alert('There was an issue redirecting you to the subscription management page. Please try again later.');
        }
    };

    const handleViewPlans = () => {
        navigate('/plans'); // Replaced history.push with navigate
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const userType = user?.user_type;
    const activeFreeTrial = user?.active_free_trial;

    return (
        <>
            {userType === 2 && !activeFreeTrial ? (
                <Button variant="primary" onClick={handleManageSubscription}>
                    Manage Subscription
                </Button>
            ) : (
                <Button
                    onClick={handleViewPlans}
                    style={{
                        backgroundColor: '#efbf04',
                        borderColor: '#efbf04',
                        color: 'black',
                    }}
                >
                    View Plans
                </Button>

            )}
        </>
    );
};

export default ManageSubscriptionButton;
