import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Table, Toast } from 'react-bootstrap';
import axios from 'axios';
import ProfileCollegeDetails from '../components/ProfileCollegeDetails';

export default function CollegeListModal({ show, handleClose, params, token }) {
  const [colleges, setColleges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);

  const fetchColleges = useCallback(async (page) => {
    try {
      const response = await axios.get('https://api.youelite.net/api/colleges', {
        params: { ...params, page },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setColleges(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching colleges:', error);
    }
  }, [params, token]);
  
  const handleAddCollege = async (college) => {
    try {
      const response = await axios.post(
        'https://api.youelite.net/api/user/colleges',
        { college_id: college.UNITID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setNotificationMessage(`College ${college.INSTNM} added successfully!`);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setNotificationMessage(`College ${college.INSTNM} is already in your list.`);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      } else {
        console.error('Failed to add college:', error);
      }
    }
  };

  const handleShowDetails = (college) => {
    setSelectedCollege(college);
    setDetailsModalShow(true);
  };

  useEffect(() => {
    if (show) {
      fetchColleges(1);
    }
  }, [show]);

  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Full Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive hover>
            <thead>
              <tr>
                <th>College Name</th>
                <th>Location</th>
                <th>Tuition</th>
                <th>Acceptance Rate</th>
                <th>Avg. SAT</th>
                <th>Scholarships</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {colleges.map(college => (
                <tr key={college.UNITID}>
                  <td>{college.INSTNM}</td>
                  <td>{college.CITY}, {college.STABBR}</td>
                  <td>${college.TUITIONFEE_IN.toLocaleString()}</td>
                  <td>{(college.ADM_RATE * 100).toFixed(2)}%</td>
                  <td>{college.SAT_AVG}</td>
                  <td>{college.SCH_DEG}</td>
                  <td className="d-flex gap-2">
                    <Button variant="primary" size="sm" onClick={() => handleShowDetails(college)}>Details</Button>
                    <Button
                      variant="primary"
                      size="sm"
                      className="btn-list"
                      onClick={() => handleAddCollege(college)}
                    >
                      List
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={() => fetchColleges(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            <span>Page {currentPage} of {totalPages}</span>
            <Button variant="secondary" onClick={() => fetchColleges(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <ProfileCollegeDetails
        show={detailsModalShow}
        onHide={() => setDetailsModalShow(false)}
        college={selectedCollege}
      />

      <Toast
        onClose={() => setShowNotification(false)}
        show={showNotification}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          minWidth: 200,
          zIndex: 1000,
          animation: 'fadeIn 0.5s, fadeOut 0.5s 2.5s'
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{notificationMessage}</Toast.Body>
      </Toast>
    </React.Fragment>
  );
}
