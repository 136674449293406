import axios from 'axios';

const API_URL = 'https://api.youelite.net/api/notifications';

// Fetch notifications
export const fetchNotifications = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || !user.token) {
      throw new Error('Authentication token not found');
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      }
    };

    const response = await axios.get(API_URL, config);
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
};

// Mark a single notification as read
export const markNotificationAsRead = async (notificationId) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || !user.token) {
      throw new Error('Authentication token not found');
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      }
    };

    const response = await axios.post(`${API_URL}/${notificationId}/read`, {}, config);
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

// Mark all notifications as read
export const markAllNotificationsAsRead = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || !user.token) {
      throw new Error('Authentication token not found');
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      }
    };

    const response = await axios.post(`${API_URL}/mark-all-read`, {}, config);
    return response.data;
  } catch (error) {
    console.error('Error marking all notifications as read:', error);
    throw error;
  }
};
