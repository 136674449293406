import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Row, Table, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PlanManagement() {
  const [plans, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editPlanId, setEditPlanId] = useState(null);
  const [newPlan, setNewPlan] = useState({
    name: "",
    description: "",
    price: "",
    features: [],
    status: "active",
    plan_level: "",
    trial_days: "",
    public_plan: "yes",
  });

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    const { token } = JSON.parse(user);

    const fetchPlans = async () => {
      try {
        const response = await axios.get("https://api.youelite.net/api/plans", {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setPlans(response.data);
      } catch (error) {
        console.error("Error fetching plans:", error.message);
      }
    };

    fetchPlans();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlan({ ...newPlan, [name]: value });
  };

  const handleFeatureChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFeatures = [...newPlan.features];
    updatedFeatures[index][name] = value;
    setNewPlan({ ...newPlan, features: updatedFeatures });
  };

  const addFeature = () => {
    setNewPlan({
      ...newPlan,
      features: [...newPlan.features, { title: "", description: "" }],
    });
  };

  const removeFeature = (index) => {
    const updatedFeatures = newPlan.features.filter((_, i) => i !== index);
    setNewPlan({ ...newPlan, features: updatedFeatures });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const user = localStorage.getItem("user");
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    const { token } = JSON.parse(user);

    try {
      if (editPlanId) {
        // Edit existing plan (PUT request)
        await axios.put(
          `https://api.youelite.net/api/plans/${editPlanId}`,
          {
            ...newPlan,
            features: JSON.stringify(newPlan.features),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setPlans((prevPlans) =>
          prevPlans.map((plan) =>
            plan.id === editPlanId ? { ...plan, ...newPlan } : plan
          )
        );
      } else {
        // Create new plan (POST request)
        const response = await axios.post(
          "https://api.youelite.net/api/plans",
          {
            ...newPlan,
            features: JSON.stringify(newPlan.features),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setPlans([...plans, response.data]);
      }

      setShowModal(false);
      setShowEditModal(false);
      setNewPlan({
        name: "",
        description: "",
        price: "",
        features: [],
        status: "active",
        plan_level: "",
        trial_days: "",
        public_plan: "yes",
      });
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const openEditModal = (plan) => {
    setEditPlanId(plan.id);
    setNewPlan({
      ...plan,
      features: plan.features ? JSON.parse(plan.features) : [],
    });
    setShowEditModal(true);
  };

  const handleDelete = async (planId) => {
    const user = localStorage.getItem("user");
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    const { token } = JSON.parse(user);

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this plan?"
    );

    if (confirmDelete) {
      try {
        await axios.delete(`https://api.youelite.net/api/plans/${planId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== planId));
      } catch (error) {
        console.error("Error deleting plan:", error.message);
      }
    }
  };

  const totalPlans = plans.length;
  const activePlans = plans.filter((plan) => plan.status === "active").length;
  const inactivePlans = totalPlans - activePlans;

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Plan Management Dashboard</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => setShowModal(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>Add New Plan
            </Button>
          </div>
        </div>

        <Row className="g-3 mb-4">
          <Col sm="4">
            <Card className="card-one"style={{padding:'0.5rem'}}>
              <Card.Body className="p-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h6 className="mb-0">Total Plans</h6>
                  <h2 className="card-value ls--1">{totalPlans}</h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="4">
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h6 className="mb-0">Active Plans</h6>
                  <h2 className="card-value ls--1">{activePlans}</h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="4">
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h6 className="mb-0">Inactive Plans</h6>
                  <h2 className="card-value ls--1">{inactivePlans}</h2>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Plans Overview</Card.Title>
              </Card.Header>
              <Card.Body className="p-3">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((plan) => (
                      <tr key={plan.id}>
                        <td>{plan.id}</td>
                        <td>{plan.name}</td>
                        <td>{plan.description}</td>
                        <td>${plan.price}</td>
                        <td>{plan.status}</td>
                        <td>
                          <Button
                            as={Link}
                            variant="primary"
                            size="sm"
                            onClick={() => openEditModal(plan)}
                          >
                            Edit
                          </Button>{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(plan.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>

      {/* Add New Plan Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newPlan.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Plan Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={newPlan.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={newPlan.price}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Features Section */}
            {newPlan.features.map((feature, index) => (
              <Row className="mb-3 align-items-end" key={index}>
                <Col md="5">
                  <Form.Group controlId={`feature_title_${index}`}>
                    <Form.Label>Feature Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={feature.title}
                      onChange={(e) => handleFeatureChange(index, e)}
                      placeholder="Enter feature title"
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId={`feature_description_${index}`}>
                    <Form.Label>Feature Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={feature.description}
                      onChange={(e) => handleFeatureChange(index, e)}
                      placeholder="Enter feature description"
                    />
                  </Form.Group>
                </Col>
                <Col
                  md="2"
                  className="d-flex align-items-center justify-content-center"
                >
                  <Button
                    variant="danger"
                    onClick={() => removeFeature(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}
            <Button variant="primary" onClick={addFeature}>
              Add Another Feature
            </Button>

            <Form.Group controlId="status" className="mt-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newPlan.status}
                onChange={handleInputChange}
                required
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="plan_level" className="mt-3">
              <Form.Label>Plan Level</Form.Label>
              <Form.Control
                type="number"
                name="plan_level"
                value={newPlan.plan_level}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="trial_days" className="mt-3">
              <Form.Label>Trial Days</Form.Label>
              <Form.Control
                type="number"
                name="trial_days"
                value={newPlan.trial_days}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="public_plan" className="mt-3">
              <Form.Label>Public Plan</Form.Label>
              <Form.Control
                as="select"
                name="public_plan"
                value={newPlan.public_plan}
                onChange={handleInputChange}
                required
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Plan Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newPlan.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Plan Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={newPlan.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={newPlan.price}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {/* Features Section */}
            {newPlan.features.map((feature, index) => (
              <Row className="mb-3 align-items-end" key={index}>
                <Col md="5">
                  <Form.Group controlId={`feature_title_${index}`}>
                    <Form.Label>Feature Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={feature.title}
                      onChange={(e) => handleFeatureChange(index, e)}
                      placeholder="Enter feature title"
                    />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group controlId={`feature_description_${index}`}>
                    <Form.Label>Feature Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={feature.description}
                      onChange={(e) => handleFeatureChange(index, e)}
                      placeholder="Enter feature description"
                    />
                  </Form.Group>
                </Col>
                <Col
                  md="2"
                  className="d-flex align-items-center justify-content-center"
                >
                  <Button
                    variant="danger"
                    onClick={() => removeFeature(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}
            <Button variant="primary" onClick={addFeature}>
              Add Another Feature
            </Button>

            <Form.Group controlId="status" className="mt-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newPlan.status}
                onChange={handleInputChange}
                required
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="plan_level" className="mt-3">
              <Form.Label>Plan Level/ID</Form.Label>
              <Form.Control
                type="number"
                name="plan_level"
                value={newPlan.plan_level}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="trial_days" className="mt-3">
              <Form.Label>Trial Days</Form.Label>
              <Form.Control
                type="number"
                name="trial_days"
                value={newPlan.trial_days}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="public_plan" className="mt-3">
              <Form.Label>Public Plan</Form.Label>
              <Form.Control
                as="select"
                name="public_plan"
                value={newPlan.public_plan}
                onChange={handleInputChange}
                required
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
