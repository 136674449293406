import React, { useState } from 'react';
import { Button, Card, Container, Form, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import pageSvg from "../assets/svg/forgot_password.svg";

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        let email = query.get('email');

        if (email) {
            email = decodeURIComponent(email);
        }

        if (!token || !email) {
            setError('Invalid or missing token/email.');
            return;
        }

        try {
            const response = await axios.post('https://api.youelite.net/api/reset-password', {
                token,
                email,
                password,
                password_confirmation: passwordConfirmation,
            });
            setMessage(response.data.message);
            setError(null);
            navigate('/signin');  // Redirect to login page after success
        } catch (err) {
            setError(err.response?.data?.message || 'Something went wrong');
            setMessage(null);
        }
    };

    return (
        <div className="page-auth">
            <div className="header">
                <Container>
                    <a href="/" className="header-logo">YouElite</a>
                </Container>
            </div>

            <div className="content">
                <Container>
                    <Card className="card-auth">
                        <Card.Body className="text-center">
                            <div className="mb-5">
                                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
                            </div>
                            <Card.Title>Reset your password</Card.Title>
                            <Card.Text className="mb-5">Enter your new password below to reset it.</Card.Text>

                            {message && <Alert variant="success">{message}</Alert>}
                            {error && <Alert variant="danger">{error}</Alert>}

                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formNewPassword" className="mb-3">
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter new password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword" className="mb-3">
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm new password"
                                        value={passwordConfirmation}
                                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100">Reset Password</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </div>
    );
}
