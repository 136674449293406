import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, ListGroup, Badge, Form, Modal } from 'react-bootstrap';
import Avatar from '../components/Avatar';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import axios from 'axios';
import "../assets/css/StudyGroupDetailsPage.css"

// Import the fallback avatar
import img1 from "../assets/img/img1.jpg";

const BASE_URL = "https://api.youelite.net/storage/";

const StudyGroupDetailsPage = () => {
  const { id } = useParams();
  const [group, setGroup] = useState(null);
  const [message, setMessage] = useState('');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [invitationStatus, setInvitationStatus] = useState(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const response = await axios.get(`/study-groups/${id}`);
        setGroup(response.data);
  
        // Check if the user is invited but hasn't accepted yet
        if (response.data.is_invited && !response.data.is_member) {
          setInvitationStatus('invited');
        } else if (response.data.is_member) {
          setInvitationStatus('member');
        } else {
          setInvitationStatus('none');
        }
      } catch (error) {
        console.error("There was an error fetching the study group data!", error);
        alert('You do not have access to this group.');
      }
    };
  
    fetchGroup();
  }, [id]);
  

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [group?.chat_messages]);


  const handleAcceptInvitation = async () => {
    try {
      const response = await axios.post(`/study-groups/${id}/accept-invitation`);
      alert('You have successfully joined the group!');
      setGroup(response.data);
      setInvitationStatus('member');
    } catch (error) {
      console.error('There was an error accepting the invitation!', error);
      alert('Failed to accept the invitation. Please try again.');
    }
  };
  


  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;
  
    try {
      const response = await axios.post(`/study-groups/${id}/chat`, { message });
      
      setGroup(prevGroup => ({
        ...prevGroup,
        chat_messages: [...prevGroup.chat_messages, response.data]
      }));
      setMessage('');
    } catch (error) {
      console.error('There was an error sending the message!', error);
    }
  };

  const handleJoinLeaveGroup = async () => {
    if (group.is_member) {
      // Handle leaving the group (not implemented yet)
      alert('Leaving the group...');
    } else {
      try {
        const response = await axios.post(`/study-groups/${id}/join`);
        alert('You have successfully requested to join the group!');
        const updatedGroup = await axios.get(`/study-groups/${id}`);
        setGroup(updatedGroup.data);
      } catch (error) {
        console.error('There was an error joining the group!', error);
        alert('Failed to join the group. Please try again.');
      }
    }
  };

  const handleInvite = async () => {
    try {
      // Send the invite using the email provided in the form
      await axios.post(`/study-groups/${id}/invite`, { email: inviteEmail });
      
      alert(`Invitation sent to ${inviteEmail}`);
      setInviteEmail('');  // Clear the email input
      setShowInviteModal(false);  // Close the modal
    } catch (error) {
      console.error('There was an error sending the invitation!', error);
      alert('Failed to send the invitation. Please try again.');
    }
  };
  
  if (!group) {
    return <div>Loading...</div>;
  }

  // Handle the case where the user doesn't have access and needs to request to join
  if (invitationStatus === 'none') {
    return (
      <div>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <div className="content-wrapper">
            <Card className="mb-4">
              <Card.Header>
                <h4>Access Restricted</h4>
              </Card.Header>
              <Card.Body>
                <p>You do not have access to this study group. Please request an invitation to join.</p>
                <Button variant="primary" onClick={handleJoinLeaveGroup}>Request to Join</Button>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  // Handle the case where the user has been invited but hasn't yet accepted
  if (invitationStatus === 'invited') {
    return (
      <div>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <div className="content-wrapper">
            <Card className="mb-4">
              <Card.Header>
                <h4>You Have Been Invited</h4>
              </Card.Header>
              <Card.Body>
                <p>You have been invited to join this study group.</p>
                <Button variant="success" onClick={handleAcceptInvitation}>Accept Invitation</Button>
                <Button variant="secondary" className="ms-2" onClick={() => setInvitationStatus('none')}>Decline Invitation</Button>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  // Handle the case where the user is a member of the group
  if (invitationStatus === 'member') {
    return (
      <div>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <div className="content-wrapper">
            <Card className="mb-4">
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{group.name}</h4>
                  <div>
                    <Badge bg="primary" pill>{group.total_members} members</Badge>
                    <Button variant="outline-primary" size="sm" className="ms-2" onClick={() => setShowInviteModal(true)}>Invite</Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <p>{group.description}</p>
                <p><strong>Created by:</strong> {group.creator.name} {group.creator.surname}</p>
                <p><strong>Created:</strong> {group.created_at}</p>
                <p><strong>Next meeting:</strong> {group.next_meeting}</p>
                <Button variant="primary" onClick={handleJoinLeaveGroup}>
                  {group.is_member ? 'Leave Group' : 'Join Group'}
                </Button>
              </Card.Body>
            </Card>

            <div className="row">
              <div className="col-md-8">
                <Card>
                  <Card.Header>
                    <h5>Group Chat</h5>
                  </Card.Header>
                  <Card.Body className="group-chat-details-area" ref={chatContainerRef}>
                    <ListGroup variant="flush">
                      {group.chat_messages.map((msg) => (
                        <ListGroup.Item key={msg.id}>
                          <strong>{msg.user?.name || 'Unknown'}:</strong> {msg.message}
                          <div className="text-muted small">{new Date(msg.created_at).toLocaleString()}</div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card.Body>
                  <Card.Footer>
                    <Form onSubmit={handleSendMessage}>
                      <Form.Group className="d-flex">
                        <Form.Control 
                          type="text" 
                          placeholder="Type a message or type / for functions"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button variant="primary" type="submit" className="ms-2">Send</Button>
                      </Form.Group>
                    </Form>
                  </Card.Footer>
                </Card>
              </div>
              <div className="col-md-4">
                <Card>
                  <Card.Header>
                    <h5>Members</h5>
                  </Card.Header>
                  <ListGroup variant="flush">
                    {group.members.map(member => (
                      <ListGroup.Item key={member.id}>
                        <div className="d-flex align-items-center">
                          <Avatar 
                            img={member.avatar && member.avatar.startsWith('http') 
                              ? member.avatar 
                              : member.avatar 
                              ? `${BASE_URL}${member.avatar}` 
                              : img1} // Use imported img1 as the fallback avatar
                            size="sm" 
                          />
                          <div className="ms-3">
                            <div>{member.name} {member.surname}</div>
                            <Badge bg={member.pivot.role === "Admin" ? "danger" : "secondary"}>{member.pivot.role}</Badge>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              </div>
            </div>
            <Footer className="footer"/>
          </div>
        </div>

        {/* Modal for inviting users */}
        <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Invite User to Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(e) => { e.preventDefault(); handleInvite(); }}>
              <Form.Group className="mb-3">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter user's email"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">Send Invitation</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return null;
};

export default StudyGroupDetailsPage;