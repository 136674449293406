import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import userAvatar from "../assets/img/img1.jpg";
import transpVector from "../assets/img/transp_vector.png";
import { fetchNotifications, markAllNotificationsAsRead } from '../services/notificationService';
import { logout } from '../services/authService';
import '../assets/css/NotificationHead.css';
import '../assets/css/FreeTrialHeader.css';

function Header({ onSkin }) {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showFreeTrialOverlay, setShowFreeTrialOverlay] = useState(false);
  const freeTrialRef = React.useRef(null);
  
  const toggleFreeTrialOverlay = (e) => {
    e.stopPropagation();
    setShowFreeTrialOverlay(!showFreeTrialOverlay);
  };

  const calculateDaysRemaining = () => {
    if (user && user.trial_started_at) {
      const trialStartDate = new Date(user.trial_started_at);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - trialStartDate);
      const diffDays = 8 - Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? diffDays : 0; // Ensure it doesn't return negative days
    }
    return 0; // Default to 0 days if no trial start date is found
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (freeTrialRef.current && !freeTrialRef.current.contains(event.target)) {
        setShowFreeTrialOverlay(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  
  
  
  useEffect(() => {
    const loadNotifications = async () => {
      const fetchedNotifications = await fetchNotifications();
      
      if (fetchedNotifications && fetchedNotifications.notifications) {
        setNotifications(fetchedNotifications.notifications);

        // Update the unread notifications count
        const unread = fetchedNotifications.notifications.filter(notification => !notification.read_at);
        setUnreadCount(unread.length);
      }
    };

    loadNotifications();
  }, []);

  const handleDropdownToggle = async (isOpen) => {
    if (isOpen) {
      await markAllNotificationsAsRead();
      setUnreadCount(0);
    }
  };

  const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  function NotificationList() {
    // Filter only unread notifications
    const unreadNotifications = notifications.filter(notification => !notification.read_at);
  
    if (unreadNotifications.length === 0) {
      return (
        <div className="no-notifications-head123">
          <i className="ri-notification-off-line" style={{ fontSize: '24px', color: '#bbb' }}></i>
          <p style={{ marginTop: '10px', color: '#777', fontSize: '14px' }}>You're all caught up!</p>
          <span style={{ color: '#aaa', fontSize: '12px' }}>No unread notifications available</span>
        </div>
      );
    }
  
    const notiList = unreadNotifications.map((item, key) => (
      <li className="list-group-item" key={key}>
        <div className="list-group-body">
          <p>{item.data.message}</p>
          <span>{formatDate(item.created_at)}</span>
        </div>
      </li>
    ));
  
    return (
      <ul className="list-group">
        {notiList}
      </ul>
    );
  }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem('skin-mode', skin);

      onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem('skin-mode');

      onSkin('');
    }
  };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };

  let user;
  let isPremium = false;
  let isFreeTrial = false;
  try {
    user = JSON.parse(localStorage.getItem('user'));
    isPremium = user && user.user_type === 2;
    isFreeTrial = user && user.active_free_trial === true;
  } catch (e) {
    user = null;
  }
  const avatarUrl = useMemo(() => {
    return user && user.avatar ? `https://api.youelite.net/storage/${user.avatar}` : userAvatar;
  }, [user]);
  
  const fullName = useMemo(() => {
    return user ? `${user.name} ${user.surname}` : 'Guest';
  }, [user]);
  

  const getUserBadge = (userType) => {
    switch (userType) {
      case 2:
        return (
          <i className="ri-vip-crown-line"></i>
        );
      case 100:
        return (
          <img src={transpVector} alt="Admin" style={{ width: '1em', height: '1em', marginRight: '0.25rem' }} />
        );
      case 90:
        return (
          <img src={transpVector} alt="Sales" style={{ width: '1em', height: '1em', marginRight: '0.25rem' }} />
        );
      case 80:
        return (
          <img src={transpVector} alt="Customer Support" style={{ width: '1em', height: '1em', marginRight: '0.25rem' }} />
        );
      case 70:
        return (
          <i className="ri-vip-crown-line"></i>
        );
      case 1:
        return (
          <span style={{ color: '#d4af37', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => window.location.href = '/plans'}>Upgrade to Premium</span>
        );
      default:
        return null;
    }
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      <div className="form-search me-auto" style={{visibility:"hidden"}}>
        <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i>
      </div>

      {isFreeTrial && (
        <div id="free-trial-badge" className="free-trial-badge me-3" ref={freeTrialRef} onClick={toggleFreeTrialOverlay}>
          Free Trial
        </div>
      )}

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label style={{display:"none"}}>Skin Mode</label>
          <nav className="nav nav-skin" style={{display:"none"}}>
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>Light</Link>
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
          <hr style={{display:"none"}} />
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link onClick={sidebarSkin} className={!(localStorage.getItem("sidebar-skin")) ? "nav-link active" : "nav-link"}>Default</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "prime") ? "nav-link active" : "nav-link"}>Prime</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "dark") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>

      <Overlay
        target={freeTrialRef.current}
        show={showFreeTrialOverlay}
        placement="bottom-start"
        rootClose
        onHide={() => setShowFreeTrialOverlay(false)}
      >
        <Popover id="youelite-free-trial-popover" className="youelite-credit-popover">
          <Popover.Body>
            <h5>Your trial is active!</h5>
            <div className="youelite-credit-info">
              <div>
                <span>Free trials expires in: </span>
                <strong>{calculateDaysRemaining()} days</strong>
              </div>
            </div>
            <p>Have fun trying our best features! <br></br><br></br><a href="/plans">We know you are curious.</a></p>
          </Popover.Body>
        </Popover>
      </Overlay>



      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end" onToggle={handleDropdownToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          {unreadCount > 0 && <small>{unreadCount}</small>}
          <i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          <NotificationList />
          <div className="dropdown-menu-footer"><Link to="/notifications">Show all Notifications</Link></div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={avatarUrl} alt="User Avatar" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><img src={avatarUrl} alt="User Avatar" /></div>
            <h5 className="mb-1 text-dark fw-semibold">{fullName}</h5>
            <p className="fs-sm text-secondary d-flex align-items-center">
              {user && getUserBadge(user.user_type)}
              {user && user.user_type === 2 && ' Premium Member'}
              {user && user.user_type === 100 && ' YouElite: Administration'}
              {user && user.user_type === 90 && ' YouElite: Sales'}
              {user && user.user_type === 80 && ' YouElite: Customer Support'}
              {user && user.user_type === 70 && ' Moderator'}
            </p>

            <nav className="nav">
              <Link to="" style={{ display: 'none' }}><i className="ri-edit-2-line"></i> Edit Profile</Link>
              <Link to="/my-profile"><i className="ri-profile-line"></i> View Profile</Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to=""><i className="ri-question-line"></i> Help Center</Link>
              <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
              <Link to="/settings"><i className="ri-user-settings-line"></i> Account Settings</Link>
              <Link onClick={logout} to="/signin"><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default React.memo(Header);