import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaGraduationCap } from "react-icons/fa";
import { LuSchool } from "react-icons/lu";
import { Nav, Row, Col, Breadcrumb, Alert } from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import { PostsTab, InfoTab, ConnectionsTab } from "../tabs/OtherSocialTabs";
import ProfileCollegeDetails from "../components/DiscoveryCollegeDetails";
import img1 from "../assets/img/img1.jpg";
import transpVector from "../assets/img/transp_vector.png";
import { Link, useParams, useNavigate } from "react-router-dom";

const stateNames = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};

// Styled components
const CollegeSection = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h5`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
`;

const CollegeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const CollegeItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e9ecef;
    transform: translateX(5px);
  }
`;

const CollegeIcon = styled(FaGraduationCap)`
  flex-shrink: 0;
  font-size: 1.2rem;
  margin-right: 0.75rem;
  color: #007bff;
`;

const CollegeName = styled.span`
  font-size: 0.9rem;
  color: #495057;
`;

const Badge = styled.span`
  font-size: 0.8rem;
  color: black;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 6px;
  border-radius: 5px;
  i {
    margin-right: 0.25rem;
  }
`;

const LogoImage = styled.img`
  width: 1em;
  height: 1em;
  margin-right: 0.25rem;
`;

const HighSchoolSection = styled.div`
  margin-bottom: 2rem;
`;

const HighSchoolItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e9ecef;
    transform: translateX(5px);
  }
`;

const HighSchoolIcon = styled(LuSchool)`
  flex-shrink: 0;
  font-size: 1.2rem;
  margin-right: 0.75rem;
  color: #007bff;
`;

const HighSchoolName = styled.span`
  font-size: 0.9rem;
  color: #495057;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const OtherProfiles = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isBioExpanded, setIsBioExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('posts');
  const [avatar, setAvatar] = useState(null);
  const [favoriteColleges, setFavoriteColleges] = useState([]);
  const [showCollegeDetails, setShowCollegeDetails] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [showAllColleges, setShowAllColleges] = useState(false); // State for showing all colleges

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem("user");
      if (!storedUser) {
        console.error("User not authenticated");
        setError("User not authenticated");
        return;
      }

      const { token } = JSON.parse(storedUser);
      console.log(`Token retrieved: ${token}`);

      try {
        const response = await fetch(`https://api.youelite.net/api/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            setError("User not found");
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return;
        }

        const data = await response.json();
        console.log("User data fetched successfully:", data);
        setUser(data);
        setAvatar(data.avatar);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError(error.toString());
      }
    };

    const fetchFavoriteColleges = async () => {
      const storedUser = localStorage.getItem("user");
      if (!storedUser) {
        console.error("User not authenticated");
        return;
      }

      const { token } = JSON.parse(storedUser);
      console.log(`Token retrieved: ${token}`);

      try {
        const response = await fetch(`https://api.youelite.net/api/user/${userId}/colleges`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Favorite colleges fetched successfully:", data);
        fetchCollegeDetails(data.slice(0, 8), token);
      } catch (error) {
        console.error("Error fetching favorite colleges:", error);
      }
    };

    const fetchCollegeDetails = async (colleges, token) => {
      const detailedColleges = await Promise.all(
        colleges.map(async (college) => {
          try {
            const response = await fetch(`https://api.youelite.net/api/colleges/${college.college_id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log(`College details for ${college.college_id} fetched successfully:`, data);
            return {
              ...college,
              name: data.INSTNM,
              ...data
            };
          } catch (error) {
            console.error(`Error fetching details for college ${college.college_id}:`, error);
            return {
              ...college,
              name: "Unable to load"
            };
          }
        })
      );

      console.log("All college details fetched:", detailedColleges);
      setFavoriteColleges(detailedColleges);
    };

    if (!userId) {
      console.error("Invalid userId");
      setError("Invalid userId");
      navigate("/dashboard"); // Redirect to the dashboard if userId is not provided
      return;
    }

    fetchUserData();
    fetchFavoriteColleges();
  }, [userId, navigate]);

  const truncateBio = (bio) => {
    if (!bio) return ''; // Return empty string if bio is null or undefined
    const words = bio.split(" ");
    const truncatedBio = words.slice(0, 30).join(" ");
    return truncatedBio.length < bio.length ? truncatedBio + "..." : bio;
  };

  const getUserBadge = (userType) => {
    switch (userType) {
      case 2:
        return (
          <Badge>
            <i className="ri-vip-crown-line"></i> Premium Member
          </Badge>
        );
      case 100:
        return (
          <Badge>
            <LogoImage src={transpVector} alt="Admin" /> YouElite: Administration
          </Badge>
        );
      case 90:
        return (
          <Badge>
            <LogoImage src={transpVector} alt="Sales" /> YouElite: Sales
          </Badge>
        );
      case 80:
        return (
          <Badge>
            <LogoImage src={transpVector} alt="Customer Support" /> YouElite: Customer Support
          </Badge>
        );
      case 70:
        return (
          <Badge>
            <i className="ri-vip-crown-line"></i> Moderator
          </Badge>
        );
      default:
        return null;
    }
  };

  const handleCollegeClick = (college) => {
    setSelectedCollege(college);
    setShowCollegeDetails(true);
  };

  const toggleCollegeView = () => {
    setShowAllColleges(!showAllColleges);
  };

  console.log("Rendering OtherProfiles component...");

  if (error) {
    return (
      <React.Fragment>
        <HeaderMobile />
        <div className="main p-4 p-lg-5">
          <Breadcrumb className="fs-sm mb-2">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>User's Profile</Breadcrumb.Item>
          </Breadcrumb>
          <Alert variant="danger" className="mt-4">
            {error}
          </Alert>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main p-4 p-lg-5">
        <Breadcrumb className="fs-sm mb-2">
          <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active>User's Profile</Breadcrumb.Item>
        </Breadcrumb>
        {user ? (
          <React.Fragment>
            <h2 className="main-title">User's Profile</h2>
            <Row className="g-5">
              <Col xl>
                <div className="media-profile mb-5">
                  <ImageContainer className="media-img mb-3 mb-sm-0">
                    <ProfileImage
                      src={avatar ? `https:api.youelite.net/storage/${avatar}` : img1}
                      alt="avatar"
                    />
                  </ImageContainer>
                  <div className="media-body">
                    <h5 className="media-name mb-0 d-inline-flex align-items-center">
                      {user ? `${user.name} ${user.surname}` : error ? "Error loading user data" : "Loading..."}
                      {user && getUserBadge(user.user_type)}
                    </h5>
                    {user && user.profile_privacy === 1 && (
                      <p className="d-flex gap-2 mb-4">
                        <i className="ri-map-pin-line"></i> {user.city}, {stateNames[user.state]}
                      </p>
                    )}
                    {user && user.profile_privacy === 1 && (
                      <p className="mb-0">
                        {!isBioExpanded ? truncateBio(user.bio) : user.bio}
                        {user.bio.length > truncateBio(user.bio).length && (
                          <Link to="#" onClick={() => setIsBioExpanded(!isBioExpanded)}>
                            {isBioExpanded ? " Show less" : " Read more"}
                          </Link>
                        )}
                      </p>
                    )}
                  </div>
                </div>

                <Nav className="nav-line mt-5 profile-tabs">
                  <Nav.Link onClick={() => setActiveTab('posts')} className={activeTab === 'posts' ? 'active' : ''}>
                    Post & Activity
                  </Nav.Link>
                  <Nav.Link onClick={() => setActiveTab('info')} className={activeTab === 'info' ? 'active' : ''}>
                    Information
                  </Nav.Link>
                  <Nav.Link onClick={() => setActiveTab('connections')} className={activeTab === 'connections' ? 'active' : ''}>
                    Connections
                  </Nav.Link>
                </Nav>

                {activeTab === 'posts' && <PostsTab />}
                {activeTab === 'info' && <InfoTab user={user} />}
                {activeTab === 'connections' && <ConnectionsTab />}
              </Col>
              <Col xl="4" xxl="3" className="d-none d-xl-block">
                <h5 className="section-title mb-4">High School</h5>
                <HighSchoolSection>
                  {user && user.profile_privacy === 0 ? (
                    <p>Private</p>
                  ) : (
                    user && user.hs_name ? (
                      <HighSchoolItem>
                        <HighSchoolIcon />
                        <HighSchoolName>{user.hs_name}</HighSchoolName>
                      </HighSchoolItem>
                    ) : (
                      <p>Nothing to show</p>
                    )
                  )}
                </HighSchoolSection>

                <hr className="my-4 opacity-0" />

                <CollegeSection>
                  <SectionTitle>Top 5 Favorite Colleges</SectionTitle>
                  {user && user.list_privacy === 0 ? (
                    <p>Private</p>
                  ) : (
                    <>
                      <CollegeList>
                        {favoriteColleges.slice(0, showAllColleges ? favoriteColleges.length : 5).map((college, index) => (
                          <CollegeItem key={index} onClick={() => handleCollegeClick(college)}>
                            <CollegeIcon />
                            <CollegeName>{college.name || 'Loading...'}</CollegeName>
                          </CollegeItem>
                        ))}
                      </CollegeList>
                      {favoriteColleges.length > 5 && (
                        <Link to="#" onClick={toggleCollegeView}>
                          {showAllColleges ? "Show less" : "Show more"}
                        </Link>
                      )}
                    </>
                  )}
                </CollegeSection>

                <hr className="my-4 opacity-0" />

                <h5 className="section-title mb-4">Social Channel</h5>
                {user && user.profile_privacy === 0 ? (
                  <p>Private</p>
                ) : (
                  <ul className="list-contact-info">
                    <li><i className="ri-twitter-fill"></i><span>{user ? user.social_twitter : "@username"}</span></li>
                    <li><i className="ri-instagram-fill"></i><span>{user ? user.social_instagram : "@username"}</span></li>
                  </ul>
                )}
              </Col>
            </Row>
            <Footer />
          </React.Fragment>
        ) : (
          <Alert variant="warning" className="mt-4">
            User not found
          </Alert>
        )}
      </div>
      <ProfileCollegeDetails
        show={showCollegeDetails}
        onHide={() => setShowCollegeDetails(false)}
        college={selectedCollege}
      />
      <style>{`
        .profile-tabs {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #dee2e6;
        }
        .profile-tabs .nav-link {
          flex: 1;
          text-align: center;
          border: none;
          color: #495057;
          padding: 0.5rem 1rem;
          margin-bottom: -1px;
        }
        .profile-tabs .nav-link.active {
          color: #007bff;
          border-bottom: 2px solid #007bff;
        }
      `}</style>
    </React.Fragment>
  );
};

export default OtherProfiles;
